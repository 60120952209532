import React from 'react';
import { Link } from 'react-router-dom';
import './main_footer.scss'
import * as routes from '../constants';
import backgroundDetails from '../images/background_details.svg';
import DefaultInput from '../utils/default_input';
import WarningMessage from '../utils/warning_message';
import {postModel} from '../utils/functions';

class MainFooter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email_subscription: "",
      sendingSubscription: false,
      subscriptionMessage: "",
      subscriptionMessageBackground: "#ffffffab",
      subscriptionMessageColor: "#292929",
    };
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if(name === 'email_subscription') {
      this.setState({
        email_subscription: value
      });
    }
  }

  async sendSubscriptionContact() {
    if (process.env.NODE_ENV === 'production') {
      window.gtag('event', 'Send subscription', {
        'event_category' : 'Form submit',
        'event_label' : 'Subscrição de email'
      });
    }

    let subscriptionMessage = '';

    if(this.state.email_subscription.length <= 0) {
      subscriptionMessage = 'E-mail não preencido.';
    }
    else if(this.state.email_subscription.match(/.+@.+\..+/) === null) {
      subscriptionMessage = 'Formato de e-mail inválido.';
    }

    if(subscriptionMessage.length > 0) {
      this.setState({
        subscriptionMessage,
        subscriptionMessageBackground: "#ffffffab",
        subscriptionMessageColor: "#292929",
      });
    }
    else {
      this.setState({
        sendingSubscription: true,
        subscriptionMessage: ""
      });

      const data = {email: this.state.email_subscription};

      try {
        if(await postModel(routes.SUBSCRIPTION_CONTACT_POST_API, data)) {
          this.setState({
            sendingSubscription: false,
            subscriptionMessage: "Subscrição confirmada.",
            subscriptionMessageBackground: "#dbf3c1a6",
            subscriptionMessageColor: "#456d19",
            email_subscription: ""
          });

          if (process.env.NODE_ENV === 'production') {
            window.gtag('event', 'Subscription confirmed', {
              'event_category' : 'Submit confirmation',
              'event_label' : 'Subscrição de email'
            });
          }
        }
      }
      catch(errors) {
        this.setState({
          sendingSubscription: false,
          subscriptionMessage: "Falha. Por favor, tente novamente mais tarde.",
          subscriptionMessageBackground: "#ffffffab",
          subscriptionMessageColor: "#292929",
        });

        return;
      }
    }
  }

  render() {
    return (
      <footer className="main-footer">

        <div className="main-footer__content">

          <div className="main-footer__wrapper">

            <div className="main-footer__column-group--1">

              <Link
                className="main-footer__logo"
                to={routes.MAIN_PATH}
                onClick={() => {
                  if (process.env.NODE_ENV === 'production') {
                    window.gtag('event', 'Click', {
                      'event_category' : 'Internal link',
                      'event_label' : 'Logo'
                    });
                  }
                }}
              >

                <div className="main-footer__logo__text-wrapper">

                  <p className="main-footer__logo__text">FYD CLUB</p>
                  <hr className="main-footer__logo__hr" />
                  <p className="main-footer__logo__sub-text">SISTEMA FYD CLUB</p>

                </div>

              </Link>

              <p className="main-footer__description">

                Metodologia e tecnologia para impulsionar o sucesso de sua academia ou estúdio

              </p>

              <ul className="main-footer__social-links-container">

                <li
                  className="main-footer__social-link-wrapper"
                  onClick={() => {
                    if (process.env.NODE_ENV === 'production') {
                      window.gtag('event', 'Click', {
                        'event_category' : 'External link',
                        'event_label' : 'Facebook'
                      });
                    }
                  }}
                >
                  <a
                    className="main-footer__social-link"
                    href="https://www.facebook.com/fyd.centrotreinamento"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>

                <li
                  className="main-footer__social-link-wrapper"
                  onClick={() => {
                    if (process.env.NODE_ENV === 'production') {
                      window.gtag('event', 'Click', {
                        'event_category' : 'External link',
                        'event_label' : 'Instagram'
                      });
                    }
                  }}
                >
                  <a
                    className="main-footer__social-link"
                    href="https://www.instagram.com/fyd_clubsc/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>

                <li
                  className="main-footer__social-link-wrapper"
                  onClick={() => {
                    if (process.env.NODE_ENV === 'production') {
                      window.gtag('event', 'Click', {
                        'event_category' : 'External link',
                        'event_label' : 'Facebook messenger'
                      });
                    }
                  }}
                >
                  <a
                    className="main-footer__social-link"
                    href="https://m.me/fyd.centrotreinamento"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-facebook-messenger"></i>
                  </a>
                </li>

              </ul>

            </div>

            <div className="main-footer__column-group--2">

              <p className="main-footer__sub-title">Contato</p>

              <address className="main-footer__address">

                <p className="main-footer__address__item">

                  <i className="fas fa-home main-footer__address__item__icon"></i>
                  <span className="main-footer__address__item__text">Av. Comendador Alfredo Maffei, 3927, Jardim Nossa Sra. Aparecida, São Carlos, SP</span>

                </p>

                {/* <p className="main-footer__address__item">

                  <i className="fas fa-clock main-footer__address__item__icon"></i>
                  <span className="main-footer__address__item__text">Seg - Sexta - 9:00 às 17:00</span>

                </p> */}

                <p className="main-footer__address__item">

                  <i className="fas fa-envelope main-footer__address__item__icon"></i>
                  <a
                    href="mailto:contato@fydclub.com.br"
                    className="main-footer__address__item__text--clickable"
                    onClick={() => {
                      if (process.env.NODE_ENV === 'production') {
                        window.gtag('event', 'Click', {
                          'event_category' : 'Email',
                          'event_label' : 'contato@fydclub.com.br'
                        });
                      }
                    }}
                  >

                    contato@fydclub.com.br

                  </a>

                </p>

              </address>

            </div>

            <div className="main-footer__column-group--3">

              <p className="main-footer__label">Gostaria de receber novidades ou promoções? Cadastre seu email:</p>

              <div className="main-footer__form-container">

                <div className="main-footer__warning-container">

                  <WarningMessage
                    message={this.state.subscriptionMessage}
                    onClose={() => this.setState({subscriptionMessage: ""})}
                    visible={this.state.subscriptionMessage.length > 0}
                    background={this.state.subscriptionMessageBackground}
                    color={this.state.subscriptionMessageColor}
                  />

                </div>

                <DefaultInput
                  className="main-footer__input"
                  name="email_subscription"
                  type="email"
                  placeholder="Digite o seu e-mail"
                  maxLength="128"
                  handleInputChange={(event) => this.handleInputChange(event)}
                  value={this.state.email_subscription}
                  autoComplete="off"
                  disabled={this.state.sendingSubscription}
                />

                <button
                  className={`main-footer__send-form-button${this.state.sendingSubscription ? '--active' : ''}`}
                  onClick={() => this.sendSubscriptionContact()}
                  disabled={this.state.sendingSubscription}
                >
                  {this.state.sendingSubscription ? 'Cadastrando...' : 'Cadastrar'}
                </button>

              </div>

            </div>

          </div>

        </div>

        <p className="main-footer__copyright">

          Copyrights © {(new Date()).getFullYear()} Todos os direitos reservados

        </p>

        <div className="main-footer__images-container">

          <div className="main-footer__red-filter"></div>

          <img
            className="main-footer__main-image"
            src={backgroundDetails}
            alt=""
          />

        </div>

      </footer>
    );
  }
}

export default MainFooter
