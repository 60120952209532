import React from 'react';
import MainHeader from '../components/main_header';
import MainFooter from '../components/main_footer';
import DefaultInput, {HalfWrapper, SelectOption} from '../utils/default_input';
import {postModel, getModel, getCpfText, getRgText, getCepText, getPhoneText, getCnpjText, setInputCursor} from '../utils/functions';
import WarningMessage from '../utils/warning_message';
import * as routes from '../constants';
import {DEFAULT_ERROR_MESSAGE} from '../constants';
import './edit_account_page.scss';

class EditAccountPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        name: "",
        email: "",
        phone: "",
        gender: "",
        birthdate: "",
        cpf: "",
        rg: "",
        profession: "",
        marital_status: "",
        nationality: "",
        personal_street: "",
        personal_number: "",
        personal_complement: "",
        personal_cep: "",
        personal_neighborhood: "",
        personal_city: "",
        personal_state: "",
        business_name: "",
        business_street: "",
        business_number: "",
        business_complement: "",
        business_cep: "",
        business_neighborhood: "",
        business_city: "",
        business_state: "",
        cnpj: "",
        responsible_cref: "",
        accepted: false
      },
      displayBusinessForm: false,
      hasAcceptedTerms: false,
      loading: true,
      processCompleted: false,
      highlights: [],
      showMessage: false,
      message: "",
      sending: false,
      messageBackground: null,
      messageColor: null,
      screenWidth: window.innerWidth,
    };
  }

  async componentDidMount() {
    try {
      const data = await getModel(`${routes.ACCOUNT_GET_API}${this.props.match.params.authHash}`);

      if(data) {
        let displayBusinessForm = this.state.displayBusinessForm;
        let hasAcceptedTerms = this.state.hasAcceptedTerms;

        if(data.cnpj) {
          displayBusinessForm = data.cnpj !== null && data.cnpj.length > 0;
        }
        if(data.accepted) {
          hasAcceptedTerms = data.accepted;
        }

        this.setState({
          displayBusinessForm,
          hasAcceptedTerms,
          data: {...this.state.data, ...data},
          loading: false
        });
      }
      else {
        this.setState({
          loading: false
        });
      }
    }
    catch(errors) {
      this.props.history.replace(routes.MAIN_PATH);
    }

    this.resizeListener = () => this.updateSize();
    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if(name === 'displayBusinessForm') {
      if(value) {
        this.setState({
          displayBusinessForm: value
        });
      }
      else {
        const data = {
          ...this.state.data,
          business_street: "",
          business_number: "",
          business_complement: "",
          business_cep: "",
          business_neighborhood: "",
          business_city: "",
          business_state: "",
          cnpj: ""
        };

        this.setState({
          displayBusinessForm: value,
          data
        });
      }

      return;
    }
    else if(value && name === 'phone') {
      const initialPosition = target.selectionStart;
      const initialSize = value.length;

      value = value.replace(/\D/g,"")

      target.value = getPhoneText(value);

      let newPosition = (target.value.length - initialSize) + initialPosition;

      if(initialPosition < initialSize) {
        newPosition = initialPosition;
      }

      if(value.length > 2 &&
          (newPosition === 4)) {
        newPosition = 6;
      }

      setInputCursor(target, newPosition);
    }
    else if(value && name === 'cpf') {
      const initialPosition = target.selectionStart;
      const initialSize = value.length;

      value = value.replace(/\D/g,"");
      target.value = getCpfText(value);

      let newPosition = (target.value.length - initialSize) + initialPosition;

      if(initialPosition < initialSize) {
        newPosition = initialPosition;
      }

      const originalValue = this.state.data[name] || '';

      const valueIncreased = originalValue.length < value.length;

      if(value.length > 3 &&
          (newPosition === 4)) {
        newPosition = valueIncreased ? 5 : 3;
      }
      else if(value.length > 6 &&
          (newPosition === 8)) {
        newPosition = valueIncreased ? 9 : 7;
      }
      else if(value.length > 9 &&
          (newPosition === 12)) {
        newPosition = valueIncreased ? 13 : 11;
      }

      setInputCursor(target, newPosition);
    }
    else if(value && name === 'rg') {
      const initialPosition = target.selectionStart;
      const initialSize = value.length;

      value = value.replace(/\D/g,"");
      target.value = getRgText(value);

      let newPosition = (target.value.length - initialSize) + initialPosition;

      if(initialPosition < initialSize) {
        newPosition = initialPosition;
      }

      const originalValue = this.state.data[name] || '';

      const valueIncreased = originalValue.length < value.length;

      if(value.length > 2 &&
          (newPosition === 3)) {
        newPosition = valueIncreased ? 4 : 2;
      }
      else if(value.length > 5 &&
          (newPosition === 7)) {
        newPosition = valueIncreased ? 8 : 6;
      }
      else if(value.length > 8 &&
          (newPosition === 11)) {
        newPosition = valueIncreased ? 12 : 10;
      }

      setInputCursor(target, newPosition);
    }
    else if(value && (name === 'personal_cep' || name === 'business_cep')) {
      const initialPosition = target.selectionStart;
      const initialSize = value.length;

      value = value.replace(/\D/g,"");
      target.value = getCepText(value);

      let newPosition = (target.value.length - initialSize) + initialPosition;

      if(initialPosition < initialSize) {
        newPosition = initialPosition;
      }

      const originalValue = this.state.data[name] || '';

      const valueIncreased = originalValue.length < value.length;

      if(value.length > 5 &&
          (newPosition === 6)) {
        newPosition = valueIncreased ? 7 : 5;
      }

      setInputCursor(target, newPosition);
    }
    else if(value && name === 'cnpj') {
      const initialPosition = target.selectionStart;
      const initialSize = value.length;

      value = value.replace(/\D/g,"");
      target.value = getCnpjText(value);

      let newPosition = (target.value.length - initialSize) + initialPosition;

      if(initialPosition < initialSize) {
        newPosition = initialPosition;
      }

      const originalValue = this.state.data[name] || '';

      const valueIncreased = originalValue.length < value.length;

      if(value.length > 2 &&
          (newPosition === 3)) {
        newPosition = valueIncreased ? 4 : 2;
      }
      else if(value.length > 5 &&
          (newPosition === 7)) {
        newPosition = valueIncreased ? 8 : 6;
      }
      else if(value.length > 8 &&
          (newPosition === 11)) {
        newPosition = valueIncreased ? 12 : 10;
      }
      else if(value.length > 12 &&
          (newPosition === 16)) {
        newPosition = valueIncreased ? 17 : 15;
      }

      setInputCursor(target, newPosition);
    }

    const data = {...this.state.data, [name]: value};

    this.setState({
      data
    });
  }

  async sendTrialContact() {
    const highlights = [];
    const messages = [];

    if(this.state.data.name.length <= 0) {
      highlights.push('name');
      messages.push('Nome não preencido');
    }

    if(this.state.data.email.length <= 0) {
      highlights.push('email');
      messages.push('E-mail não preencido');
    }
    else if(this.state.data.email.match(/.+@.+\..+/) === null) {
      highlights.push('email');
      messages.push('Formato de e-mail inválido');
    }

    if(this.state.data.phone.length <= 0) {
      highlights.push('phone');
      messages.push('Telefone de contato não preencido');
    }
    else if(this.state.data.phone.length < 10) {
      highlights.push('phone');
      messages.push('DDD de telefone para contato não preenchido');
    }

    if(this.state.data.gender.length <= 0) {
      highlights.push('gender');
      messages.push('Gênero não selecionado');
    }

    if(this.state.data.birthdate.length <= 0) {
      highlights.push('birthdate');
      messages.push('Data de nascimento não preenchida');
    }

    if(this.state.data.cpf.length < 11) {
      highlights.push('cpf');
      messages.push('CPF não preenchido');
    }

    if(this.state.data.rg.length <= 0) {
      highlights.push('rg');
      messages.push('RG não preenchido');
    }

    if(this.state.data.profession.length <= 0) {
      highlights.push('profession');
      messages.push('Profissão não preenchido');
    }

    if(this.state.data.marital_status.length <= 0) {
      highlights.push('marital_status');
      messages.push('Estado civil não preenchido');
    }

    if(this.state.data.nationality.length <= 0) {
      highlights.push('nationality');
      messages.push('Nacionalidade não preenchida');
    }

    if(this.state.data.personal_street.length <= 0) {
      highlights.push('personal_street');
      messages.push('Endereço pessoal não preenchido');
    }

    if(this.state.data.personal_number.length <= 0) {
      highlights.push('personal_number');
      messages.push('Número de endereço pessoal não preenchido');
    }

    if(this.state.data.personal_cep.length < 8) {
      highlights.push('personal_cep');
      messages.push('CEP de endereço pessoal preenchido');
    }

    if(this.state.data.personal_neighborhood.length <= 0) {
      highlights.push('personal_neighborhood');
      messages.push('Bairro de endereço pessoal não preenchido');
    }

    if(this.state.data.personal_city.length <= 0) {
      highlights.push('personal_city');
      messages.push('Cidade de endereço pessoal não preenchida');
    }

    if(this.state.data.personal_state.length <= 0) {
      highlights.push('personal_state');
      messages.push('Estado de endereço pessoal não preenchido');
    }

    if(this.state.data.business_name.length <= 0) {
      highlights.push('business_name');
      messages.push('Nome do negócio não preenchido');
    }

    if(this.state.data.responsible_cref.length <= 0) {
      highlights.push('responsible_cref');
      messages.push('Número CREF não preenchido');
    }

    if(this.state.displayBusinessForm) {
      if(this.state.data.cnpj === null || this.state.data.cnpj.length < 14) {
        highlights.push('cnpj');
        messages.push('CNPJ não preenchido');
      }

      if(this.state.data.business_street === null || this.state.data.business_street.length <= 0) {
        highlights.push('business_street');
        messages.push('Endereço da empresa não preenchido');
      }

      if(this.state.data.business_number === null || this.state.data.business_number.length <= 0) {
        highlights.push('business_number');
        messages.push('Número de endereço da empresa não preenchido');
      }

      if(this.state.data.business_cep === null || this.state.data.business_cep.length < 8) {
        highlights.push('business_cep');
        messages.push('CEP de endereço da empresa preenchido');
      }

      if(this.state.data.business_neighborhood === null || this.state.data.business_neighborhood.length <= 0) {
        highlights.push('business_neighborhood');
        messages.push('Bairro de endereço da empresa não preenchido');
      }

      if(this.state.data.business_city === null || this.state.data.business_city.length <= 0) {
        highlights.push('business_city');
        messages.push('Cidade de endereço da empresa não preenchida');
      }

      if(this.state.data.business_state === null || this.state.data.business_state.length <= 0) {
        highlights.push('business_state');
        messages.push('Estado de endereço da empresa não preenchido');
      }
    }

    if(highlights.length > 0) {
      this.setState({
        highlights,
        showMessage: true,
        message: `${messages.join('. ')}.`,
        messageBackground: null,
        messageColor: null
      });

      window.scrollTo(0, 0);
    }
    else {
      this.setState({
        highlights: [],
        sending: true,
        message: "",
        showMessage: false
      });

      const data = {...this.state.data};

      try {
        if(await postModel(`${routes.ACCOUNT_POST_API}${this.props.match.params.authHash}`, data)) {
          let message = this.state.hasAcceptedTerms ? 'Cadastro atualizado com sucesso!' : 'Cadastro submetido. Assim que processarmos os seus dados entraremos em contato para habilitarmos sua conta. Agradecemos novamente pela confiança!'
          this.setState({
            sending: false,
            showMessage: true,
            processCompleted: true,
            message,
            messageBackground: "#dbf3c1",
            messageColor: "#456d19"
          });

          window.scrollTo(0, 0);
        }
      }
      catch(errors) {
        let highlights = [];
        let message = DEFAULT_ERROR_MESSAGE;

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              case 103:
                for(let parameter of error.parameters) {
                  switch (parameter.name) {
                    case 'email':
                      message = 'E-mail já está cadastrado para outra conta de teste.';
                      highlights.push('email');

                      break;
                    default:
                  }
                }

                break;
              default:
            }
          }
        }

        this.setState({
          highlights,
          sending: false,
          showMessage: true,
          message,
          messageBackground: null,
          messageColor: null
        });

        window.scrollTo(0, 0);

        return;
      }
    }
  }

  getBusinessForm() {
    if(this.state.displayBusinessForm) {
      return (
        <React.Fragment>

          <DefaultInput
            className="edit-account-page__input"
            name="cnpj"
            isHighlighted={this.state.highlights.includes('cnpj')}
            label="CNPJ"
            type="text"
            placeholder="Número do CNPJ"
            maxLength="18"
            handleInputChange={(event) => this.handleInputChange(event)}
            value={getCnpjText(this.state.data.cnpj)}
            autoComplete="off"
            disabled={this.state.sending}
          />

          <div className="edit-account-page__form-sub-section">

            <hr className="edit-account-page__form-ruler" />

            <h3 className="edit-account-page__form-sub-section__title">Endereço da empresa</h3>

          </div>

          <HalfWrapper>

            <DefaultInput
              className="edit-account-page__input"
              name="business_street"
              isHighlighted={this.state.highlights.includes('business_street')}
              label="Endereço"
              type="text"
              placeholder="Logradouro"
              maxLength="128"
              handleInputChange={(event) => this.handleInputChange(event)}
              value={this.state.data.business_street || ''}
              autoComplete="off"
              disabled={this.state.sending}
            />

            <DefaultInput
              className="edit-account-page__input"
              name="business_number"
              isHighlighted={this.state.highlights.includes('business_number')}
              label="Número"
              type="text"
              placeholder="Número"
              maxLength="16"
              handleInputChange={(event) => this.handleInputChange(event)}
              value={this.state.data.business_number || ''}
              autoComplete="off"
              disabled={this.state.sending}
            />

          </HalfWrapper>

          <DefaultInput
            className="edit-account-page__input"
            name="business_complement"
            isHighlighted={this.state.highlights.includes('business_complement')}
            label="Complemento"
            type="text"
            placeholder="Complemento de endereço"
            maxLength="128"
            handleInputChange={(event) => this.handleInputChange(event)}
            value={this.state.data.business_complement || ''}
            autoComplete="off"
            disabled={this.state.sending}
          />

          <HalfWrapper>

            <DefaultInput
              className="edit-account-page__input"
              name="business_neighborhood"
              isHighlighted={this.state.highlights.includes('business_neighborhood')}
              label="Bairro"
              type="text"
              placeholder="Bairro"
              maxLength="64"
              handleInputChange={(event) => this.handleInputChange(event)}
              value={this.state.data.business_neighborhood || ''}
              autoComplete="off"
              disabled={this.state.sending}
            />

            <DefaultInput
              className="edit-account-page__input"
              name="business_cep"
              isHighlighted={this.state.highlights.includes('business_cep')}
              label="CEP"
              type="text"
              placeholder="CEP"
              maxLength="9"
              handleInputChange={(event) => this.handleInputChange(event)}
              value={getCepText(this.state.data.business_cep) || ''}
              autoComplete="off"
              disabled={this.state.sending}
            />

          </HalfWrapper>

          <HalfWrapper>

            <DefaultInput
              className="edit-account-page__input"
              name="business_city"
              isHighlighted={this.state.highlights.includes('business_city')}
              label="Cidade"
              type="text"
              placeholder="Cidade"
              maxLength="32"
              handleInputChange={(event) => this.handleInputChange(event)}
              value={this.state.data.business_city || ''}
              autoComplete="off"
              disabled={this.state.sending}
            />

            <DefaultInput
              className="edit-account-page__input"
              name="business_state"
              isHighlighted={this.state.highlights.includes('business_state')}
              label="Estado"
              type="text"
              placeholder="Estado do aluno"
              maxLength="32"
              handleInputChange={(event) => this.handleInputChange(event)}
              value={this.state.data.business_state || ''}
              autoComplete="off"
              disabled={this.state.sending}
            />

          </HalfWrapper>

        </React.Fragment>
      );
    }

    return null;
  }

  getSubmitText() {
    if(this.state.hasAcceptedTerms) {
      if(this.state.sending) {
        return 'Atualizando...';
      }
      else {
        return 'Atualizar cadastro';
      }
    }
    else {
      if(this.state.sending) {
        return 'Enviando...';
      }
      else {
        return 'Submeter cadastro';
      }
    }
  }

  getMainContent() {
    if(this.state.loading) {
      return (
        <div className="edit-account-page__loading-container">

          <i className="fas fa-spinner edit-account-page__loading-icon"></i>

        </div>
      );
    }
    else if(!this.state.processCompleted) {
      return (
        <div className="edit-account-page__form-container">

          <div className="edit-account-page__warning-container">

            <WarningMessage
              message={this.state.message}
              onClose={() => this.setState({highlights: [], showMessage: false})}
              visible={this.state.showMessage}
              background={this.state.messageBackground}
              color={this.state.messageColor}
            />

          </div>

          <div className="edit-account-page__form-sub-section">

            <hr className="edit-account-page__form-ruler" />

            <h3 className="edit-account-page__form-sub-section__title">Dados pessoais</h3>

          </div>

          <DefaultInput
            className="edit-account-page__input"
            name="name"
            label="Nome"
            type="text"
            isHighlighted={this.state.highlights.includes('name')}
            placeholder="Nome"
            maxLength="128"
            handleInputChange={(event) => this.handleInputChange(event)}
            value={this.state.data.name}
            autoComplete="off"
            disabled={this.state.sending}
          />

          <DefaultInput
            className="edit-account-page__input"
            name="email"
            label="E-mail"
            type="email"
            isHighlighted={this.state.highlights.includes('email')}
            placeholder="E-mail"
            maxLength="128"
            handleInputChange={(event) => this.handleInputChange(event)}
            value={this.state.data.email}
            autoComplete="off"
            disabled={this.state.sending}
          />

          <HalfWrapper>

            <DefaultInput
              className="edit-account-page__input"
              name="phone"
              label="Telefone"
              isHighlighted={this.state.highlights.includes('phone')}
              type="tel"
              placeholder="Telefone"
              maxLength="16"
              handleInputChange={(event) => this.handleInputChange(event)}
              value={getPhoneText(this.state.data.phone)}
              autoComplete="off"
              disabled={this.state.sending}
            />

            <DefaultInput
              className="edit-account-page__input"
              name="gender"
              label="Gênero"
              isHighlighted={this.state.highlights.includes('gender')}
              type="select"
              handleInputChange={(event) => this.handleInputChange(event)}
              value={this.state.data.gender || ''}
              options={[
                SelectOption('', 'Selecione seu gênero'),
                SelectOption('Masculino', 'Masculino'),
                SelectOption('Feminino', 'Feminino'),
              ]}
              disabled={this.state.sending}
            />

          </HalfWrapper>

          <DefaultInput
            className="edit-account-page__input"
            name="birthdate"
            isHighlighted={this.state.highlights.includes('birthdate')}
            type="date"
            label="Data de nascimento"
            placeholder="Data de nascimento"
            handleInputChange={(event) => this.handleInputChange(event)}
            value={this.state.data.birthdate}
            disabled={this.state.sending}
          />

          <HalfWrapper>

            <DefaultInput
              className="edit-account-page__input"
              name="cpf"
              isHighlighted={this.state.highlights.includes('cpf')}
              label="CPF"
              type="text"
              placeholder="Número do CPF"
              maxLength="14"
              handleInputChange={(event) => this.handleInputChange(event)}
              value={getCpfText(this.state.data.cpf)}
              autoComplete="off"
              disabled={this.state.sending}
            />

            <DefaultInput
              className="edit-account-page__input"
              name="rg"
              isHighlighted={this.state.highlights.includes('rg')}
              label="RG"
              type="text"
              placeholder="Número do RG"
              maxLength="16"
              handleInputChange={(event) => this.handleInputChange(event)}
              value={getRgText(this.state.data.rg)}
              autoComplete="off"
              disabled={this.state.sending}
            />

          </HalfWrapper>

          <DefaultInput
            className="edit-account-page__input"
            name="profession"
            isHighlighted={this.state.highlights.includes('profession')}
            label="Profissão"
            type="text"
            placeholder="Profissão"
            maxLength="32"
            handleInputChange={(event) => this.handleInputChange(event)}
            value={this.state.data.profession}
            autoComplete="off"
            disabled={this.state.sending}
          />

          <HalfWrapper>

            <DefaultInput
              className="edit-account-page__input"
              name="marital_status"
              isHighlighted={this.state.highlights.includes('marital_status')}
              label="Estado civil"
              type="text"
              placeholder="Estado civil"
              maxLength="32"
              handleInputChange={(event) => this.handleInputChange(event)}
              value={this.state.data.marital_status}
              autoComplete="off"
              disabled={this.state.sending}
            />

            <DefaultInput
              className="edit-account-page__input"
              name="nationality"
              isHighlighted={this.state.highlights.includes('nationality')}
              label="Nacionalidade"
              type="text"
              placeholder="Nacionalidade"
              maxLength="32"
              handleInputChange={(event) => this.handleInputChange(event)}
              value={this.state.data.nationality}
              autoComplete="off"
              disabled={this.state.sending}
            />

          </HalfWrapper>

          <DefaultInput
            className="edit-account-page__input"
            name="responsible_cref"
            isHighlighted={this.state.highlights.includes('responsible_cref')}
            label="Número do CREF"
            type="text"
            placeholder="CREF"
            maxLength="32"
            handleInputChange={(event) => this.handleInputChange(event)}
            value={this.state.data.responsible_cref}
            autoComplete="off"
            disabled={this.state.sending}
          />

          <div className="edit-account-page__form-sub-section">

            <hr className="edit-account-page__form-ruler" />

            <h3 className="edit-account-page__form-sub-section__title">Endereço pessoal</h3>

          </div>

          <HalfWrapper>

            <DefaultInput
              className="edit-account-page__input"
              name="personal_street"
              isHighlighted={this.state.highlights.includes('personal_street')}
              label="Endereço"
              type="text"
              placeholder="Logradouro"
              maxLength="128"
              handleInputChange={(event) => this.handleInputChange(event)}
              value={this.state.data.personal_street}
              autoComplete="off"
              disabled={this.state.sending}
            />

            <DefaultInput
              className="edit-account-page__input"
              name="personal_number"
              isHighlighted={this.state.highlights.includes('personal_number')}
              label="Número"
              type="text"
              placeholder="Número"
              maxLength="16"
              handleInputChange={(event) => this.handleInputChange(event)}
              value={this.state.data.personal_number}
              autoComplete="off"
              disabled={this.state.sending}
            />

          </HalfWrapper>

          <DefaultInput
            className="edit-account-page__input"
            name="personal_complement"
            isHighlighted={this.state.highlights.includes('personal_complement')}
            label="Complemento"
            type="text"
            placeholder="Complemento de endereço"
            maxLength="128"
            handleInputChange={(event) => this.handleInputChange(event)}
            value={this.state.data.personal_complement || ''}
            autoComplete="off"
            disabled={this.state.sending}
          />

          <HalfWrapper>

            <DefaultInput
              className="edit-account-page__input"
              name="personal_neighborhood"
              isHighlighted={this.state.highlights.includes('personal_neighborhood')}
              label="Bairro"
              type="text"
              placeholder="Bairro"
              maxLength="64"
              handleInputChange={(event) => this.handleInputChange(event)}
              value={this.state.data.personal_neighborhood}
              autoComplete="off"
              disabled={this.state.sending}
            />

            <DefaultInput
              className="edit-account-page__input"
              name="personal_cep"
              isHighlighted={this.state.highlights.includes('personal_cep')}
              label="CEP"
              type="text"
              placeholder="CEP"
              maxLength="9"
              handleInputChange={(event) => this.handleInputChange(event)}
              value={getCepText(this.state.data.personal_cep)}
              autoComplete="off"
              disabled={this.state.sending}
            />

          </HalfWrapper>

          <HalfWrapper>

            <DefaultInput
              className="edit-account-page__input"
              name="personal_city"
              isHighlighted={this.state.highlights.includes('personal_city')}
              label="Cidade"
              type="text"
              placeholder="Cidade"
              maxLength="32"
              handleInputChange={(event) => this.handleInputChange(event)}
              value={this.state.data.personal_city}
              autoComplete="off"
              disabled={this.state.sending}
            />

            <DefaultInput
              className="edit-account-page__input"
              name="personal_state"
              isHighlighted={this.state.highlights.includes('personal_state')}
              label="Estado"
              type="text"
              placeholder="Estado do aluno"
              maxLength="32"
              handleInputChange={(event) => this.handleInputChange(event)}
              value={this.state.data.personal_state}
              autoComplete="off"
              disabled={this.state.sending}
            />

          </HalfWrapper>

          <div className="edit-account-page__form-sub-section">

            <hr className="edit-account-page__form-ruler" />

            <h3 className="edit-account-page__form-sub-section__title">Dados do negócio</h3>

          </div>

          <DefaultInput
            className="edit-account-page__input"
            name="business_name"
            label="Nome do negócio"
            type="text"
            isHighlighted={this.state.highlights.includes('business_name')}
            placeholder="Nome do negócio"
            maxLength="128"
            handleInputChange={(event) => this.handleInputChange(event)}
            value={this.state.data.business_name}
            autoComplete="off"
            disabled={this.state.sending}
          />

          <DefaultInput
            className="edit-account-page__input"
            name="displayBusinessForm"
            label="Possui CNPJ?"
            type="toggle"
            isHorizontal={true}
            activeText="Sim"
            inactiveText="Não"
            handleInputChange={(event) => this.handleInputChange(event)}
            value={this.state.displayBusinessForm}
            horizontalAlign="right"
          />

          {this.getBusinessForm()}

          {!this.state.hasAcceptedTerms &&
            <div className="edit-account-page__accept-input-container">

              <input
                className="edit-account-page__accept-input"
                type="checkbox"
                id="accepted"
                name="accepted"
                onChange={(event) => this.handleInputChange(event)}
                checked={this.state.data.accepted}
              />

              <label htmlFor="accepted" className="edit-account-page__accept-input-label">

                Declaro que li e concordo com os
                <a
                  className="edit-account-page__download-link"
                  href={`${window.location.protocol}//${window.location.host.replace(':3000', ':5000')}${routes.TERM_OF_USE_GET_PATH}`}
                  rel="noopener noreferrer"
                  target="_blank"
                  download
                >

                  termos de uso do sistema FYD FIT

                </a>
                e confirmo que meus dados apresentados estão corretos.

              </label>

            </div>
          }

          <button
            className={`edit-account-page__send-form-button${this.state.sending ? '--active' : ''}`}
            onClick={() => this.sendTrialContact()}
            disabled={this.state.sending || !this.state.data.accepted}
          >

            {this.getSubmitText()}

          </button>

        </div>
      );
    }
    else {
      return (
        <div className="edit-account-page__form-container">

          <div className="edit-account-page__warning-container">

            <WarningMessage
              message={this.state.message}
              onClose={() => this.setState({highlights: [], showMessage: false})}
              visible={this.state.showMessage}
              background={this.state.messageBackground}
              color={this.state.messageColor}
            />

          </div>

        </div>
      );
    }
  }

  render() {
    return (
      <React.Fragment>

        <MainHeader
          location={this.props.location}
          visibleByDefault={true}
          basePath={routes.MAIN_PATH}
        />

        <div className="edit-account-page__wrapper">

          <main className="edit-account-page">

            <div className="edit-account-page__content">

              <h2 className="edit-account-page__title">

                <span className="edit-account-page__title--highlighted">Cadastro</span> de conta

              </h2>

              <hr className="edit-account-page__hr"></hr>

              <p className="edit-account-page__semi-title">

                Preencha corretamente os dados abaixo para que possamos habilitar sua conta definitiva

              </p>

              {this.getMainContent()}

            </div>

          </main>

          <MainFooter />

        </div>

      </React.Fragment>
    );
  }
}

export default EditAccountPage
