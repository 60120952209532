const DEFAULT_ERROR_MESSAGE = `Infelizmente estamos com problemas em nosso serviço. ` +
                              `Certifique-se de que esteja conectado na internet e, ` +
                              `por favor, tente novamente mais tarde.`;

const STATE_API = '/request_state';

const DEFAULT_CONTACT_POST_API = '/contact-request';
const SUBSCRIPTION_CONTACT_POST_API = '/subscription-request';
const MEETING_REQUEST_POST_API = '/meeting-request';

const ACCOUNT_GET_API = '/account_data/';
const ACCOUNT_POST_API = '/account_data/';

export {
  DEFAULT_ERROR_MESSAGE,
  STATE_API,
  DEFAULT_CONTACT_POST_API,
  SUBSCRIPTION_CONTACT_POST_API,
  MEETING_REQUEST_POST_API,
  ACCOUNT_GET_API,
  ACCOUNT_POST_API
};


const MAIN_PATH = '/';

const MEETING_FORM_PATH = '/agendar-apresentacao';
const EDIT_ACCOUNT_PATH = '/habilitar-conta/';

const TERM_OF_USE_GET_PATH = '/terms-of-use';

export {
  MAIN_PATH,
  MEETING_FORM_PATH,
  EDIT_ACCOUNT_PATH,
  TERM_OF_USE_GET_PATH
};
