import React from 'react';
import { Link } from 'react-router-dom';
import './main_header.scss'
import * as routes from '../constants';
import logo from '../images/logo_fyd_club.png';

class MainHeader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      screenWidth: window.innerWidth,
      scrollPosition: window.scrollY,
      menuVisible: false
    };
  }

  async componentDidMount() {
    this.resizeListener = () => this.updateSize();
    this.scrollListener = () => this.updateScroll();

    window.addEventListener("resize", this.resizeListener);
    window.addEventListener("scroll", this.scrollListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
    window.removeEventListener("scroll", this.scrollListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  updateScroll() {
    this.setState({
      scrollPosition: window.scrollY
    });
  }

  getBasePath() {
    if(this.props.basePath) {
      return this.props.basePath;
    }

    return '';
  }

  getNavLink(text, path) {
    // if(this.props.location.pathname === path) {
    //   return (
    //     <li className="main-header__navigation__link-wrapper--selected">
    //       <p className="main-header__navigation__link--selected">{text}</p>
    //     </li>
    //   );
    // }

    return (
      <li
        className={`main-header__navigation__link-wrapper${this.props.blackHover ? '--black-hover' : ''}`}
        onClick={() => {
          if (process.env.NODE_ENV === 'production') {
            window.gtag('event', 'Click', {
              'event_category' : 'Internal link',
              'event_label' : text
            });
          }
        }}
      >
        <a href={this.getBasePath() + path} className={`main-header__navigation__link`}>{text}</a>
      </li>
    );
  }

  getMainClass() {
    if(this.state.scrollPosition > 0) {
      return 'main-header--scrolled';
    }
    else if(this.props.visibleByDefault) {
      return 'main-header--visible';
    }

    return 'main-header';
  }

  render() {
    if(this.state.screenWidth <= 750) {
      return (
        <React.Fragment>

          <header className={this.getMainClass()}>

            <div className="main-header__wrapper">

              <div className="main-header__controls-container">

                <Link
                  className="main-header__logo"
                  to={routes.MAIN_PATH}
                  onClick={() => {
                    if (process.env.NODE_ENV === 'production') {
                      window.gtag('event', 'Click', {
                        'event_category' : 'Internal link',
                        'event_label' : 'Logo'
                      });
                    }
                  }}
                >

                  <img
                    className="main-header__logo__image"
                    src={logo}
                    alt="FYD Club logo"
                  />

                  <div className="main-header__logo__text-wrapper">

                    <p className={`main-header__logo__text${this.state.menuVisible ? '--red' : ''}`}>FYD CLUB</p>
                    <hr className={`main-header__logo__hr${this.state.menuVisible ? '--red' : ''}`} />
                    <p className={`main-header__logo__sub-text${this.state.menuVisible ? '--red' : ''}`}>SISTEMA FYD CLUB</p>

                  </div>

                </Link>

                <button
                  className={`main-header__menu-toggler${this.state.menuVisible ? '--on' : ''}`}
                  type="button"
                  onClick={() => this.setState({menuVisible: !this.state.menuVisible})}
                >
                  <i className="fas fa-bars"></i>
                </button>

              </div>

              <div className={`main-header__toggle-container${this.state.menuVisible ? '--visible' : ''}`}>

                <nav className="main-header__navigation">

                  <ul className="main-header__navigation__links-container">

                    {this.getNavLink('Início', '#')}
                    {this.getNavLink('Investimento', '#values-section')}
                    {this.getNavLink('Trailer', '#video-section')}
                    {this.getNavLink('Contato', '#contact-section')}

                  </ul>

                </nav>

              </div>

              <aside className="main-header__social-media-container">

                <ul className="main-header__navigation__social-links-container">

                  <li
                    className="main-header__navigation__social-link-wrapper"
                    onClick={() => {
                      if (process.env.NODE_ENV === 'production') {
                        window.gtag('event', 'Click', {
                          'event_category' : 'External link',
                          'event_label' : 'Facebook'
                        });
                      }
                    }}
                  >
                    <a
                      className="main-header__navigation__social-link"
                      href="https://www.facebook.com/fyd.centrotreinamento"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>

                  <li
                    className="main-header__navigation__social-link-wrapper"
                    onClick={() => {
                      if (process.env.NODE_ENV === 'production') {
                        window.gtag('event', 'Click', {
                          'event_category' : 'External link',
                          'event_label' : 'Instagram'
                        });
                      }
                    }}
                  >
                    <a
                      className="main-header__navigation__social-link"
                      href="https://www.instagram.com/fyd_clubsc/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>

                  <li
                    className="main-header__navigation__social-link-wrapper"
                    onClick={() => {
                      if (process.env.NODE_ENV === 'production') {
                        window.gtag('event', 'Click', {
                          'event_category' : 'External link',
                          'event_label' : 'Facebook messenger'
                        });
                      }
                    }}
                  >
                    <a
                      className="main-header__navigation__social-link"
                      href="https://m.me/fyd.centrotreinamento"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-facebook-messenger"></i>
                    </a>
                  </li>

                </ul>

              </aside>

            </div>

          </header>

          <div
            className={`main-header__toggle-background-block${this.state.menuVisible ? '--visible' : ''}`}
            onClick={() => this.setState({menuVisible: !this.state.menuVisible})}
          ></div>

        </React.Fragment>
      );
    }

    return (
      <header className={this.getMainClass()}>

        <div className="main-header__wrapper">

          <Link
            className="main-header__logo"
            to={routes.MAIN_PATH}
            onClick={() => {
              if (process.env.NODE_ENV === 'production') {
                window.gtag('event', 'Click', {
                  'event_category' : 'Internal link',
                  'event_label' : 'Logo'
                });
              }
            }}
          >

            <img
              className="main-header__logo__image"
              src={logo}
              alt="FYD Club logo"
            />

            <div className="main-header__logo__text-wrapper">

              <p className="main-header__logo__text">FYD CLUB</p>
              <hr className="main-header__logo__hr" />
              <p className="main-header__logo__sub-text">SISTEMA FYD CLUB</p>

            </div>


          </Link>

          <div className="main-header__controls-container">

            <nav className={`main-header__navigation${this.state.scrollPosition <= 0 && this.state.screenWidth <= 910 ? '--hidden' : ''}`}>

              <ul className="main-header__navigation__links-container">

                {this.getNavLink('Início', '#')}
                {this.getNavLink('Investimento', '#values-section')}
                {this.getNavLink('Trailer', '#video-section')}
                {this.getNavLink('Contato', '#contact-section')}

              </ul>

            </nav>

            <aside className="main-header__social-media-container">

              <ul className={`main-header__navigation__social-links-container${this.state.scrollPosition <= 0 && this.state.screenWidth <= 910 ? '--no-border' : ''}`}>

                <li
                  className="main-header__navigation__social-link-wrapper"
                  onClick={() => {
                    if (process.env.NODE_ENV === 'production') {
                      window.gtag('event', 'Click', {
                        'event_category' : 'External link',
                        'event_label' : 'Facebook'
                      });
                    }
                  }}
                >
                  <a
                    className="main-header__navigation__social-link"
                    href="https://www.facebook.com/fyd.centrotreinamento"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>

                <li
                  className="main-header__navigation__social-link-wrapper"
                  onClick={() => {
                    if (process.env.NODE_ENV === 'production') {
                      window.gtag('event', 'Click', {
                        'event_category' : 'External link',
                        'event_label' : 'Instagram'
                      });
                    }
                  }}
                >
                  <a
                    className="main-header__navigation__social-link"
                    href="https://www.instagram.com/fyd_clubsc/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>

                <li
                  className="main-header__navigation__social-link-wrapper"
                  onClick={() => {
                    if (process.env.NODE_ENV === 'production') {
                      window.gtag('event', 'Click', {
                        'event_category' : 'External link',
                        'event_label' : 'Facebook messenger'
                      });
                    }
                  }}
                >
                  <a
                    className="main-header__navigation__social-link"
                    href="https://m.me/fyd.centrotreinamento"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-facebook-messenger"></i>
                  </a>
                </li>

              </ul>

            </aside>

          </div>

        </div>

      </header>
    );
  }
}

export default MainHeader
