import React from 'react';
import MainHeader from '../components/main_header';
import MainFooter from '../components/main_footer';
import DefaultInput from '../utils/default_input';
import {postModel, getPhoneText, setInputCursor} from '../utils/functions';
import WarningMessage from '../utils/warning_message';
import * as routes from '../constants';
import {DEFAULT_ERROR_MESSAGE} from '../constants';
import './meeting_form_page.scss';

class MeetingFormPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        name: "",
        email: "",
        phone: "",
      },
      highlights: [],
      showMessage: false,
      message: "",
      sending: false,
      messageBackground: null,
      messageColor: null,
      screenWidth: window.innerWidth,
    };
  }

  async componentDidMount() {
    if (process.env.NODE_ENV === 'production') {
      window.gtag('config', 'UA-151418680-3', {'page_path': routes.MEETING_FORM_PATH});
    }

    this.resizeListener = () => this.updateSize();
    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if(value && name === 'phone') {
      const initialPosition = target.selectionStart;
      const initialSize = value.length;

      value = value.replace(/\D/g,"")

      target.value = getPhoneText(value);

      let newPosition = (target.value.length - initialSize) + initialPosition;

      if(initialPosition < initialSize) {
        newPosition = initialPosition;
      }

      if(value.length > 2 &&
          (newPosition === 4)) {
        newPosition = 6;
      }

      setInputCursor(target, newPosition);
    }

    const data = {...this.state.data, [name]: value};

    this.setState({
      data
    });
  }

  async sendMeetingRequest() {
    if (process.env.NODE_ENV === 'production') {
      window.gtag('event', 'Send meeting request', {
        'event_category' : 'Form submit',
        'event_label' : 'Apresentação'
      });
    }

    const highlights = [];
    const messages = [];

    if(this.state.data.name.length <= 0) {
      highlights.push('name');
      messages.push('Nome não preencido');
    }

    if(this.state.data.email.length <= 0) {
      highlights.push('email');
      messages.push('E-mail não preencido');
    }
    else if(this.state.data.email.match(/.+@.+\..+/) === null) {
      highlights.push('email');
      messages.push('Formato de e-mail inválido');
    }

    if(this.state.data.phone.length <= 0) {
      highlights.push('phone');
      messages.push('Telefone de contato não preencido');
    }
    else if(this.state.data.phone.length < 10) {
      highlights.push('phone');
      messages.push('DDD de telefone para contato não preenchido');
    }

    if(highlights.length > 0) {
      this.setState({
        highlights,
        showMessage: true,
        message: `${messages.join('. ')}.`,
        messageBackground: null,
        messageColor: null
      });

      window.scrollTo(0, 0);
    }
    else {
      this.setState({
        highlights: [],
        sending: true,
        message: "",
        showMessage: false
      });

      const data = {...this.state.data};

      try {
        if(await postModel(routes.MEETING_REQUEST_POST_API, data)) {
          this.setState({
            sending: false,
            showMessage: true,
            message: "Agradecemos pela confiança! Em breve entraremos em contato para agendarmos a apresentação.",
            messageBackground: "#dbf3c1",
            messageColor: "#456d19",
            data: {
              name: "",
              email: "",
              phone: "",
            }
          });

          if (process.env.NODE_ENV === 'production') {
            window.gtag('event', 'Meeting request confirmation', {
              'event_category' : 'Submit confirmation',
              'event_label' : 'Conta teste'
            });
          }

          window.scrollTo(0, 0);
        }
      }
      catch(errors) {
        let highlights = [];
        let message = DEFAULT_ERROR_MESSAGE;

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              case 103:
                for(let parameter of error.parameters) {
                  switch (parameter.name) {
                    case 'email':
                      message = 'Email já está cadastrado. Caso possua qualquer dúvida adicional, por favor, entre em contato conosco para que possamos lhe ajudar.';
                      highlights.push('email');

                      break;
                    default:
                  }
                }

                break;
              default:
            }
          }
        }

        this.setState({
          highlights,
          sending: false,
          showMessage: true,
          message,
          messageBackground: null,
          messageColor: null
        });

        window.scrollTo(0, 0);

        return;
      }
    }
  }

  render() {
    return (
      <React.Fragment>

        <MainHeader
          location={this.props.location}
          visibleByDefault={true}
          basePath={routes.MAIN_PATH}
          blackHover={true}
        />

        <div className="meeting-form-page__wrapper">

          <main className="meeting-form-page">

            <div className="meeting-form-page__content">

              <h2 className="meeting-form-page__title">

                Agendar <span className="meeting-form-page__title--highlighted">apresentação</span>

              </h2>

              <hr className="meeting-form-page__hr"></hr>

              <p className="meeting-form-page__semi-title">

                Informe os dados do formulário para agendar uma apresentação sem nenhum compromisso

              </p>

              <div className="meeting-form-page__form-container">

                <div className="meeting-form-page__warning-container">

                  <WarningMessage
                    message={this.state.message}
                    onClose={() => this.setState({highlights: [], showMessage: false})}
                    visible={this.state.showMessage}
                    background={this.state.messageBackground}
                    color={this.state.messageColor}
                  />

                </div>

                <DefaultInput
                  className="meeting-form-page__input"
                  name="name"
                  type="text"
                  isHighlighted={this.state.highlights.includes('name')}
                  placeholder="Nome"
                  maxLength="128"
                  handleInputChange={(event) => this.handleInputChange(event)}
                  value={this.state.data.name}
                  autoComplete="off"
                  disabled={this.state.sending}
                />

                <DefaultInput
                  className="meeting-form-page__input"
                  name="email"
                  type="email"
                  isHighlighted={this.state.highlights.includes('email')}
                  placeholder="E-mail"
                  maxLength="128"
                  handleInputChange={(event) => this.handleInputChange(event)}
                  value={this.state.data.email}
                  autoComplete="off"
                  disabled={this.state.sending}
                />

                <DefaultInput
                  className="meeting-form-page__input"
                  name="phone"
                  isHighlighted={this.state.highlights.includes('phone')}
                  type="tel"
                  placeholder="Telefone"
                  maxLength="16"
                  handleInputChange={(event) => this.handleInputChange(event)}
                  value={getPhoneText(this.state.data.phone)}
                  autoComplete="off"
                  disabled={this.state.sending}
                />

                <button
                  className={`meeting-form-page__send-form-button${this.state.sending ? '--active' : ''}`}
                  onClick={() => this.sendMeetingRequest()}
                  disabled={this.state.sending}
                >
                  {this.state.sending ? 'Requisitando...' : 'Requisitar apresentação'}
                </button>

              </div>

            </div>

          </main>

          <MainFooter />

        </div>

      </React.Fragment>
    );
  }
}

export default MeetingFormPage
