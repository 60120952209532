import React from 'react';
import { Link } from 'react-router-dom';
import MainHeader from '../components/main_header';
import MainFooter from '../components/main_footer';
import './main_page.scss';
import panoramicExtraLarge from '../images/fyd_panoramic_extra_large.jpg';
import panoramicExtraLarge2x from '../images/fyd_panoramic_extra_large_2x.jpg';
import panoramicLarge from '../images/fyd_panoramic_cropped_large.jpg';
import panoramicLarge2x from '../images/fyd_panoramic_cropped_large_2x.jpg';
import panoramicMedium from '../images/fyd_panoramic_cropped_medium.jpg';
import panoramicMedium2x from '../images/fyd_panoramic_cropped_medium_2x.jpg';
import panoramicSmall from '../images/fyd_panoramic_cropped_small.jpg';
import panoramicSmall2x from '../images/fyd_panoramic_cropped_small_2x.jpg';
import panoramicVerySmall from '../images/fyd_panoramic_cropped_very_small.jpg';
import panoramicVerySmall2x from '../images/fyd_panoramic_cropped_very_small_2x.jpg';
import panoramicTiny from '../images/fyd_panoramic_cropped_tiny.jpg';
import panoramicTiny2x from '../images/fyd_panoramic_cropped_tiny_2x.jpg';

import mainBannerLarge from '../images/main_banner_large.jpg';
import mainBannerLarge2x from '../images/main_banner_large_2x.jpg';
import mainBannerBig from '../images/main_banner_big.jpg';
import mainBannerBig2x from '../images/main_banner_big_2x.jpg';
import mainBannerMedium from '../images/main_banner_medium.jpg';
import mainBannerMedium2x from '../images/main_banner_medium_2x.jpg';
import mainBannerSmall from '../images/main_banner_small.jpg';
import mainBannerSmall2x from '../images/main_banner_small_2x.jpg';
import mainBannerVerySmall from '../images/main_banner_very_small.jpg';
import mainBannerVerySmall2x from '../images/main_banner_very_small_2x.jpg';
import mainBannerTiny from '../images/main_banner_tiny.jpg';
import mainBannerTiny2x from '../images/main_banner_tiny_2x.jpg';
import mainBannerVeryTiny from '../images/main_banner_very_tiny.jpg';
import mainBannerVeryTiny2x from '../images/main_banner_very_tiny_2x.jpg';

import callToActionExtraLarge from '../images/call_to_action_extra_large.jpg';
import callToActionExtraLarge2x from '../images/call_to_action_extra_large_2x.jpg';
import callToActionLarge from '../images/call_to_action_large.jpg';
import callToActionLarge2x from '../images/call_to_action_large_2x.jpg';
import callToActionBig from '../images/call_to_action_big.jpg';
import callToActionBig2x from '../images/call_to_action_big_2x.jpg';
import callToActionMedium from '../images/call_to_action_medium.jpg';
import callToActionMedium2x from '../images/call_to_action_medium_2x.jpg';
import callToActionSmall from '../images/call_to_action_small.jpg';
import callToActionSmall2x from '../images/call_to_action_small_2x.jpg';
import callToActionVerySmall from '../images/call_to_action_very_small.jpg';
import callToActionVerySmall2x from '../images/call_to_action_very_small_2x.jpg';
import callToActionTiny from '../images/call_to_action_tiny.jpg';
import callToActionTiny2x from '../images/call_to_action_tiny_2x.jpg';
import callToActionVeryTiny from '../images/call_to_action_very_tiny.jpg';
import callToActionVeryTiny2x from '../images/call_to_action_very_tiny_2x.jpg';

import arrow from '../images/arrow.svg';
import hexagon from '../images/hexagon.svg';
import backgroundDetails from '../images/background_details.svg';

import projectionPhoto1Large from '../images/projection_photo_1_large.jpg';
import projectionPhoto1Large2x from '../images/projection_photo_1_large_2x.jpg';
import projectionPhoto1Medium from '../images/projection_photo_1_medium.jpg';
import projectionPhoto1Medium2x from '../images/projection_photo_1_medium_2x.jpg';
import projectionPhoto1Small from '../images/projection_photo_1_small.jpg';
import projectionPhoto1Small2x from '../images/projection_photo_1_small_2x.jpg';

import projectionPhoto2Large from '../images/projection_photo_2_large.jpg';
import projectionPhoto2Large2x from '../images/projection_photo_2_large_2x.jpg';
import projectionPhoto2Medium from '../images/projection_photo_2_medium.jpg';
import projectionPhoto2Medium2x from '../images/projection_photo_2_medium_2x.jpg';
import projectionPhoto2Small from '../images/projection_photo_2_small.jpg';
import projectionPhoto2Small2x from '../images/projection_photo_2_small_2x.jpg';

import projectionPhoto3Large from '../images/projection_photo_3_large.jpg';
import projectionPhoto3Large2x from '../images/projection_photo_3_large_2x.jpg';

import featureClassboard from '../images/feature_classboard.png';
import featureOnlineForm from '../images/feature_online_form.png';
import featureFinancial from '../images/feature_financial.png';

import DefaultInput from '../utils/default_input';
import {postModel} from '../utils/functions';
import WarningMessage from '../utils/warning_message';
import * as routes from '../constants';
import {DEFAULT_ERROR_MESSAGE} from '../constants';

class MainPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contact: {
        name: "",
        email: "",
        message: ""
      },
      contactHighlights: [],
      showContactMessage: false,
      contactMessage: "",
      sendingContact: false,
      contactMessageBackground: null,
      contactMessageColor: null,
      screenWidth: window.innerWidth,
      projectionAnimationStarted: false,
      projectionAnimationSetUp: false,
      fydLetterVisible: false,
      callAnimationtriggered: false,
    };

    // Projection border
    this.projectionAnimation1 = React.createRef();
    this.projectionAnimation1Reference = React.createRef();
    this.projectionAnimation2 = React.createRef();
    this.projectionAnimation2CircleReference = React.createRef();
    this.projectionAnimation3 = React.createRef();
    this.projectionAnimation4 = React.createRef();
    this.projectionAnimation4Reference = React.createRef();
    this.projectionAnimation4CircleReference = React.createRef();
    this.projectionAnimation5 = React.createRef();
    this.projectionAnimation6 = React.createRef();
    this.projectionAnimation6Reference = React.createRef();
    this.projectionAnimation6CircleReference = React.createRef();
    this.projectionAnimation7 = React.createRef();

    this.projectionAnimation7 = React.createRef();

    // Project bullets
    this.projectioBullet1Icon = React.createRef();
    this.projectioBullet1Line = React.createRef();
    this.projectioBullet1Text = React.createRef();
    this.projectioBullet1SubText = React.createRef();
    this.projectioBullet1PhotoContainer = React.createRef();
    this.projectioBullet1Photo = React.createRef();

    this.projectioBullet2Icon = React.createRef();
    this.projectioBullet2Line = React.createRef();
    this.projectioBullet2Text = React.createRef();
    this.projectioBullet2SubText = React.createRef();
    this.projectioBullet2PhotoContainer = React.createRef();
    this.projectioBullet2Photo = React.createRef();

    this.projectioBullet3Icon = React.createRef();
    this.projectioBullet3Line = React.createRef();
    this.projectioBullet3Text = React.createRef();
    this.projectioBullet3SubText = React.createRef();
    this.projectioBullet3PhotoContainer = React.createRef();
    this.projectioBullet3Photo = React.createRef();

    // FYD letters
    this.fydBackground = React.createRef();
    this.fLetterGroup = React.createRef();
    this.fLetters = React.createRef();
    this.yLetterGroup = React.createRef();
    this.yLetters = React.createRef();
    this.dLetterGroup = React.createRef();
    this.dLetters = React.createRef();

    // Call to action
    this.callTextContainer = React.createRef();
    this.callText1 = React.createRef();
    this.callText2 = React.createRef();
    this.callButton = React.createRef();
  }

  setupAnimations() {
    const speed = this.state.screenWidth <= 500 ? 750 : 1000;

    const element1 = this.projectionAnimation1.current;
    const element1Reference = this.projectionAnimation1Reference.current;
    const element2 = this.projectionAnimation2.current;
    const element2CircleReference = this.projectionAnimation2CircleReference.current;
    const element3 = this.projectionAnimation3.current;
    const element4 = this.projectionAnimation4.current;
    const element4Reference = this.projectionAnimation4Reference.current;
    const element4CircleReference = this.projectionAnimation4CircleReference.current;
    const element5 = this.projectionAnimation5.current;
    const element6 = this.projectionAnimation6.current;
    const element6Reference = this.projectionAnimation6Reference.current;
    const element6CircleReference = this.projectionAnimation6CircleReference.current;
    const element7 = this.projectionAnimation7.current;

    // Border
    const element1ReferenceRect = element1Reference.getBoundingClientRect();
    const element4ReferenceRect = element4Reference.getBoundingClientRect();
    const element6ReferenceRect = element6Reference.getBoundingClientRect();
    const element1Width = element1ReferenceRect.right + 1;

    let currentTime = 0;
    let playState = 'paused';

    if(this.animation1) {
      currentTime = this.animation1.currentTime / this.animation1Durantion;
      playState = this.animation1.playState;
    }

    this.animation1Durantion = 1000*element1Width/speed;
    this.animation1 = element1.animate([
      {
        width: `${element1Width}px`,
        transform: 'translateX(-100%)'
      },
      {
        width: `${element1Width}px`,
        transform: 'translateX(0)'
      },
    ],
    {
      duration: this.animation1Durantion,
      iterations: 1,
      fill: "both"
    });
    this.animation1.currentTime = currentTime * this.animation1Durantion;
    if(playState === 'running') {
      this.animation1.play();
    }
    else {
      this.animation1.pause();
    }

    currentTime = 0;
    playState = 'paused';
    const element2Radius = element2CircleReference.getBoundingClientRect().width;

    if(this.animation2) {
      currentTime = this.animation2.currentTime / this.animation2Durantion;
      playState = this.animation2.playState;
    }

    this.animation2Durantion = 1000*Math.PI*element2Radius/speed;
    this.animation2 = element2.animate([
      {
        transform: 'rotate(0)'
      },
      {
        transform: 'rotate(180deg)'
      },
    ],
    {
      duration: this.animation2Durantion,
      iterations: 1,
      fill: "both"
    });
    this.animation2.currentTime = currentTime * this.animation2Durantion;
    if(playState === 'running') {
      this.animation2.play();
    }
    else {
      this.animation2.pause();
    }

    currentTime = 0;
    playState = 'paused';
    const element1ReferenceRight = element1ReferenceRect.right;
    const element4ReferenceLeft = element4ReferenceRect.left;
    const element3Width = element1ReferenceRight - element4ReferenceLeft;

    if(this.animation3) {
      currentTime = this.animation3.currentTime / this.animation3Durantion;
      playState = this.animation3.playState;
    }

    this.animation3Durantion = 1000*element3Width/speed;
    this.animation3 = element3.animate([
      {
        width: '0',
        left: `${element3Width}px`
      },
      {
        width: `${element3Width}px`,
        left: '0'
      },
    ],
    {
      duration: this.animation3Durantion,
      iterations: 1,
      fill: "both"
    });
    this.animation3.currentTime = currentTime * this.animation3Durantion;
    if(playState === 'running') {
      this.animation3.play();
    }
    else {
      this.animation3.pause();
    }

    currentTime = 0;
    playState = 'paused';
    const element4Radius = element4CircleReference.getBoundingClientRect().width;

    if(this.animation4) {
      currentTime = this.animation4.currentTime / this.animation4Durantion;
      playState = this.animation4.playState;
    }

    this.animation4Durantion = 1000*Math.PI*element4Radius/speed;
    this.animation4 = element4.animate([
      {
        transform: 'rotate(0)'
      },
      {
        transform: 'rotate(-180deg)'
      },
    ],
    {
      duration: this.animation4Durantion,
      iterations: 1,
      fill: "both"
    });
    this.animation4.currentTime = currentTime * this.animation4Durantion;
    if(playState === 'running') {
      this.animation4.play();
    }
    else {
      this.animation4.pause();
    }

    currentTime = 0;
    playState = 'paused';
    const element6ReferenceRight = element6ReferenceRect.right;
    const element5Width = element6ReferenceRight - element4ReferenceLeft;

    if(this.animation5) {
      currentTime = this.animation5.currentTime / this.animation5Durantion;
      playState = this.animation5.playState;
    }

    this.animation5Durantion = 1000*element5Width/speed;
    this.animation5 = element5.animate([
      {
        width: '0',
        right: `${element5Width}px`
      },
      {
        width: `${element5Width}px`,
        right: '0'
      },
    ],
    {
      duration: this.animation5Durantion,
      iterations: 1,
      fill: "both"
    });
    this.animation5.currentTime = currentTime * this.animation5Durantion;
    if(playState === 'running') {
      this.animation5.play();
    }
    else {
      this.animation5.pause();
    }

    currentTime = 0;
    playState = 'paused';
    const element6Radius = element6CircleReference.getBoundingClientRect().width;

    if(this.animation6) {
      currentTime = this.animation6.currentTime / this.animation6Durantion;
      playState = this.animation6.playState;
    }

    this.animation6Durantion = 1000*Math.PI*element6Radius/speed;
    this.animation6 = element6.animate([
      {
        transform: 'rotate(0)'
      },
      {
        transform: 'rotate(180deg)'
      },
    ],
    {
      duration: this.animation6Durantion,
      iterations: 1,
      fill: "both"
    });
    this.animation6.currentTime = currentTime * this.animation6Durantion;
    if(playState === 'running') {
      this.animation6.play();
    }
    else {
      this.animation6.pause();
    }

    currentTime = 0;
    playState = 'paused';
    const element7Width = element6ReferenceRect.right + 1;

    if(this.animation7) {
      currentTime = this.animation7.currentTime / this.animation7Durantion;
      playState = this.animation7.playState;
    }

    this.animation7Durantion = 1000*element7Width/speed;
    this.animation7 = element7.animate([
      {
        width: '0'
      },
      {
        width: `${element7Width}px`
      },
    ],
    {
      duration: this.animation7Durantion,
      iterations: 1,
      fill: "both"
    });
    this.animation7.currentTime = currentTime * this.animation7Durantion;
    if(playState === 'running') {
      this.animation7.play();
    }
    else {
      this.animation7.pause();
    }

    const bulletAnimationPeriod = 1200;

    if(!this.state.projectionAnimationSetUp) {
      // Bullets
      const bullet1IconElement = this.projectioBullet1Icon.current;
      const bullet1LineElement = this.projectioBullet1Line.current;
      const bullet1TextElement = this.projectioBullet1Text.current;
      const bullet1SubTextElement = this.projectioBullet1SubText.current;

      const bullet2IconElement = this.projectioBullet2Icon.current;
      const bullet2LineElement = this.projectioBullet2Line.current;
      const bullet2TextElement = this.projectioBullet2Text.current;
      const bullet2SubTextElement = this.projectioBullet2SubText.current;

      const bullet3IconElement = this.projectioBullet3Icon.current;
      const bullet3LineElement = this.projectioBullet3Line.current;
      const bullet3TextElement = this.projectioBullet3Text.current;
      const bullet3SubTextElement = this.projectioBullet3SubText.current;

      this.bullet1IconAnimation = bullet1IconElement.animate([
        {
          opacity: '0',
          transform: 'rotate(0)'
        },
        {
          opacity: '1',
          transform: `rotate(${360*2}deg)`
        },
      ],
      {
        duration: bulletAnimationPeriod,
        iterations: 1,
        fill: "both",
        easing: "ease-out"
      });
      this.bullet1IconAnimation.pause();

      this.bullet1LineAnimation = bullet1LineElement.animate([
        {
          opacity: '0',
          width: '0',
        },
        {
          opacity: '1',
          width: '105%',
        },
      ],
      {
        duration: bulletAnimationPeriod,
        iterations: 1,
        fill: "both",
        easing: "ease-out"
      });
      this.bullet1LineAnimation.pause();

      this.bullet1TextAnimation = bullet1TextElement.animate([
        {
          opacity: '0',
          transform: 'translateY(-0.5em)'
        },
        {
          opacity: '1',
          transform: 'translateY(0)'
        },
      ],
      {
        duration: 0.15*bulletAnimationPeriod,
        delay: 0.75*bulletAnimationPeriod,
        iterations: 1,
        fill: "both",
        easing: "ease-out"
      });
      this.bullet1TextAnimation.pause();

      this.bullet1SubTextAnimation = bullet1SubTextElement.animate([
        {
          opacity: '0',
          transform: 'translateY(0.5em)'
        },
        {
          opacity: '1',
          transform: 'translateY(0)'
        },
      ],
      {
        duration: 0.15*bulletAnimationPeriod,
        delay: 0.85*bulletAnimationPeriod,
        iterations: 1,
        fill: "both",
        easing: "ease-out"
      });
      this.bullet1SubTextAnimation.pause();

      this.bullet2IconAnimation = bullet2IconElement.animate([
        {
          opacity: '0',
          transform: 'rotate(0)'
        },
        {
          opacity: '1',
          transform: `rotate(-${360*2}deg)`
        },
      ],
      {
        duration: bulletAnimationPeriod,
        iterations: 1,
        fill: "both",
        easing: "ease-out"
      });
      this.bullet2IconAnimation.pause();

      this.bullet2LineAnimation = bullet2LineElement.animate([
        {
          opacity: '0',
          width: '0',
        },
        {
          opacity: '1',
          width: '105%',
        },
      ],
      {
        duration: bulletAnimationPeriod,
        iterations: 1,
        fill: "both",
        easing: "ease-out"
      });
      this.bullet2LineAnimation.pause();

      this.bullet2TextAnimation = bullet2TextElement.animate([
        {
          opacity: '0',
          transform: 'translateY(-0.5em)'
        },
        {
          opacity: '1',
          transform: 'translateY(0)'
        },
      ],
      {
        duration: 0.15*bulletAnimationPeriod,
        delay: 0.75*bulletAnimationPeriod,
        iterations: 1,
        fill: "both",
        easing: "ease-out"
      });
      this.bullet2TextAnimation.pause();

      this.bullet2SubTextAnimation = bullet2SubTextElement.animate([
        {
          opacity: '0',
          transform: 'translateY(0.5em)'
        },
        {
          opacity: '1',
          transform: 'translateY(0)'
        },
      ],
      {
        duration: 0.15*bulletAnimationPeriod,
        delay: 0.85*bulletAnimationPeriod,
        iterations: 1,
        fill: "both",
        easing: "ease-out"
      });
      this.bullet2SubTextAnimation.pause();

      this.bullet3IconAnimation = bullet3IconElement.animate([
        {
          opacity: '0',
          transform: 'rotate(0)'
        },
        {
          opacity: '1',
          transform: `rotate(${360*2}deg)`
        },
      ],
      {
        duration: bulletAnimationPeriod,
        iterations: 1,
        fill: "both",
        easing: "ease-out"
      });
      this.bullet3IconAnimation.pause();

      this.bullet3LineAnimation = bullet3LineElement.animate([
        {
          opacity: '0',
          width: '0',
        },
        {
          opacity: '1',
          width: '105%',
        },
      ],
      {
        duration: bulletAnimationPeriod,
        iterations: 1,
        fill: "both",
        easing: "ease-out"
      });
      this.bullet3LineAnimation.pause();

      this.bullet3TextAnimation = bullet3TextElement.animate([
        {
          opacity: '0',
          transform: 'translateY(-0.5em)'
        },
        {
          opacity: '1',
          transform: 'translateY(0)'
        },
      ],
      {
        duration: 0.15*bulletAnimationPeriod,
        delay: 0.75*bulletAnimationPeriod,
        iterations: 1,
        fill: "both",
        easing: "ease-out"
      });
      this.bullet3TextAnimation.pause();

      this.bullet3SubTextAnimation = bullet3SubTextElement.animate([
        {
          opacity: '0',
          transform: 'translateY(0.5em)'
        },
        {
          opacity: '1',
          transform: 'translateY(0)'
        },
      ],
      {
        duration: 0.15*bulletAnimationPeriod,
        delay: 0.85*bulletAnimationPeriod,
        iterations: 1,
        fill: "both",
        easing: "ease-out"
      });
      this.bullet3SubTextAnimation.pause();

      const fydBackgroundElement = this.fydBackground.current;
      const fLetterGroupElement = this.fLetterGroup.current;
      const fLettersElement = this.fLetters.current;
      // const yLetterGroupElement = this.yLetterGroup.current;
      const yLettersElement = this.yLetters.current;
      const dLetterGroupElement = this.dLetterGroup.current;
      const dLettersElement = this.dLetters.current;

      const animationSetup = {
        duration: 800,
        delay: 0,
        iterations: 1,
        fill: "both",
        easing: "ease-out"
      };

      const lettersAnimation = [
        {
          transform: 'translateX(-100%)'
        },
        {
          transform: 'translateX(0)'
        }
      ];

      this.fydBackgroundAnimation = fydBackgroundElement.animate(
        [
          {
            width: '5.5em',
            transform: 'translateX(-1.1em)'
          },
          {
            width: '16em',
            transform: 'translateX(0)'
          }
        ],
        animationSetup);

      this.fLetterGroupAnimation = fLetterGroupElement.animate(
        [
          {
            transform: 'translateX(4.3em)'
          },
          {
            transform: 'translateX(0)'
          }
        ],
        animationSetup);
      this.fLettersAnimation = fLettersElement.animate(
        lettersAnimation,
        animationSetup);
      // const yLetterGroupAnimation = yLetterGroupElement.animate(
      //   [
      //     {
      //       transform: 'translateX(100%)'
      //     },
      //     {
      //       transform: 'translateX(0)'
      //     }
      //   ],
      //   letterGroupAnimationSetup);
      this.yLettersAnimation = yLettersElement.animate(
        lettersAnimation,
        animationSetup);
      this.dLetterGroupAnimation = dLetterGroupElement.animate(
        [
          {
            transform: 'translateX(-2.7em)'
          },
          {
            transform: 'translateX(0)'
          }
        ],
        animationSetup);

      this.dLettersAnimation = dLettersElement.animate(
        lettersAnimation,
        animationSetup);

      this.fydBackgroundAnimation.pause();
      this.fLetterGroupAnimation.pause();
      this.fLettersAnimation.pause();
      this.yLettersAnimation.pause();
      this.dLetterGroupAnimation.pause();
      this.dLettersAnimation.pause();
    }

    const callTextContainerElement = this.callTextContainer.current;
    const callText1Element = this.callText1.current;
    const callText2Element = this.callText2.current;
    const callButtonElement = this.callButton.current;

    currentTime = 0;
    playState = 'paused';

    if(this.callTextContainerAnimation) {
      currentTime = this.callTextContainerAnimation.currentTime;
      playState = this.callTextContainerAnimation.playState;
    }

    const callText1Height = callText1Element.getBoundingClientRect().height;
    const callText2Height = callText2Element.getBoundingClientRect().height;
    // const callTextContainerHeight = callText1Height + callText2Height + this.state.screenWidth <= 1200 ? (this.state.screenWidth <= 600 ? '11.3em' : '12em') : '14em';
    const callTextContainerHeight = callText1Height + callText2Height + (this.state.screenWidth <= 1200 ? (this.state.screenWidth <= 600 ? (this.state.screenWidth <= 380 ? callText1Height*0.25 : callText1Height*0.35) : callText1Height*0.5) : callText1Height*0.9);

    this.callTextContainerAnimation = callTextContainerElement.animate(
      [
        {
          height: '0',
          padding: this.state.screenWidth <= 470 ? '0 0.4em 0 0' : '0 0.8em 0 0'
        },
        {
          height: `${callTextContainerHeight}px`,
          padding: this.state.screenWidth <= 470 ? (this.state.screenWidth <= 380 ? '0.15em 0.4em 0.15em 0' : '0.3em 0.4em 0.3em 0') : '0.3em 0.8em 0.3em 0'
        },
      ],
      {
        duration: 1000,
        delay: 0,
        iterations: 1,
        fill: "both",
        easing: "ease-out"
      });
    this.callTextContainerAnimation.currentTime = currentTime;
    if(playState === 'running') {
      this.callTextContainerAnimation.play();
    }
    else {
      this.callTextContainerAnimation.pause();
    }

    currentTime = 0;
    playState = 'paused';

    if(this.callText1Animation) {
      currentTime = this.callText1Animation.currentTime;
      playState = this.callText1Animation.playState;
    }
    this.callText1Animation = callText1Element.animate(
      [
        {
          transform: 'translateX(110%)'
        },
        {
          transform: 'translateX(0)'
        },
      ],
      {
        duration: 1500,
        delay: 0,
        iterations: 1,
        fill: "both",
        easing: "ease-out"
      });
    this.callText1Animation.currentTime = currentTime;
    if(playState === 'running') {
      this.callText1Animation.play();
    }
    else {
      this.callText1Animation.pause();
    }

    currentTime = 0;
    playState = 'paused';

    if(this.callText2Animation) {
      currentTime = this.callText2Animation.currentTime;
      playState = this.callText2Animation.playState;
    }
    this.callText2Animation = callText2Element.animate(
      [
        {
          transform: 'translateX(110%)'
        },
        {
          transform: 'translateX(0)'
        },
      ],
      {
        duration: 1500,
        delay: 500,
        iterations: 1,
        fill: "both",
        easing: "ease-out"
      });
    this.callText2Animation.currentTime = currentTime;
    if(playState === 'running') {
      this.callText2Animation.play();
    }
    else {
      this.callText2Animation.pause();
    }

    currentTime = 0;
    playState = 'paused';

    if(this.callButtonAnimation) {
      currentTime = this.callButtonAnimation.currentTime;
      playState = this.callButtonAnimation.playState;
    }
    this.callButtonAnimation = callButtonElement.animate(
      [
        {
          opacity: '0',
          transform: 'translateY(20%)'
        },
        {
          opacity: '1',
          transform: 'translateY(0)'
        },
      ],
      {
        duration: 1000,
        delay: 200,
        iterations: 1,
        fill: "both",
        easing: "ease-out"
      });
    this.callButtonAnimation.currentTime = currentTime;
    if(playState === 'running') {
      this.callButtonAnimation.play();
    }
    else {
      this.callButtonAnimation.pause();
    }

    this.callTextContainerAnimation.onfinish = () => {
      this.callText1Animation.play();
      this.callText2Animation.play();
    }
    this.callText2Animation.onfinish = () => {
      this.callButtonAnimation.play();
    }

    if(this.state.screenWidth > 770) {
      const bullet1PhotoContainerElement = this.projectioBullet1PhotoContainer.current;
      const bullet1PhotoElement = this.projectioBullet1Photo.current;
      const bullet2PhotoContainerElement = this.projectioBullet2PhotoContainer.current;
      const bullet2PhotoElement = this.projectioBullet2Photo.current;
      const bullet3PhotoContainerElement = this.projectioBullet3PhotoContainer.current;
      const bullet3PhotoElement = this.projectioBullet3Photo.current;

      currentTime = 0;
      playState = 'paused';

      if(this.bullet1PhotoContainerAnimation) {
        currentTime = this.bullet1PhotoContainerAnimation.currentTime;
        playState = this.bullet1PhotoContainerAnimation.playState;
      }
      this.bullet1PhotoContainerAnimation = bullet1PhotoContainerElement.animate([
        {
          opacity: '0',
          transform: 'scale(0.8)'
        },
        {
          opacity: '1',
          transform: 'scale(1.0)'
        },
      ],
      {
        duration: 0.25*bulletAnimationPeriod,
        delay: 0,
        iterations: 1,
        fill: "both",
        easing: "ease-out"
      });
      this.bullet1PhotoContainerAnimation.currentTime = currentTime;
      if(playState === 'running' || (this.bullet3SubTextAnimation && this.bullet3SubTextAnimation.playState === 'finished')) {
        this.bullet1PhotoContainerAnimation.play();
      }
      else {
        this.bullet1PhotoContainerAnimation.pause();
      }

      currentTime = 0;
      playState = 'paused';

      if(this.bullet1PhotoAnimation) {
        currentTime = this.bullet1PhotoAnimation.currentTime;
        playState = this.bullet1PhotoAnimation.playState;
      }
      this.bullet1PhotoAnimation = bullet1PhotoElement.animate([
        {
          transform: 'translate(100%, -100%) rotate(90deg) scale(1.07)'
        },
        {
          transform: 'translate(0, 0) rotate(6deg) scale(1.07)'
        },
      ],
      {
        duration: 0.6*bulletAnimationPeriod,
        delay: 0.25*bulletAnimationPeriod,
        iterations: 1,
        fill: "both",
        easing: "ease-out"
      });
      this.bullet1PhotoAnimation.currentTime = currentTime;
      if(playState === 'running'  || (this.bullet3SubTextAnimation && this.bullet3SubTextAnimation.playState === 'finished')) {
        this.bullet1PhotoAnimation.play();
      }
      else {
        this.bullet1PhotoAnimation.pause();
      }

      currentTime = 0;
      playState = 'paused';

      if(this.bullet2PhotoContainerAnimation) {
        currentTime = this.bullet2PhotoContainerAnimation.currentTime;
        playState = this.bullet2PhotoContainerAnimation.playState;
      }
      this.bullet2PhotoContainerAnimation = bullet2PhotoContainerElement.animate([
        {
          opacity: '0',
          transform: 'scale(0.8)'
        },
        {
          opacity: '1',
          transform: 'scale(1.0)'
        },
      ],
      {
        duration: 0.25*bulletAnimationPeriod,
        delay: 0,
        iterations: 1,
        fill: "both",
        easing: "ease-out"
      });
      this.bullet2PhotoContainerAnimation.currentTime = currentTime;
      if(playState === 'running') {
        this.bullet2PhotoContainerAnimation.play();
      }
      else {
        this.bullet2PhotoContainerAnimation.pause();
      }

      currentTime = 0;
      playState = 'paused';

      if(this.bullet2PhotoAnimation) {
        currentTime = this.bullet2PhotoAnimation.currentTime;
        playState = this.bullet2PhotoAnimation.playState;
      }
      this.bullet2PhotoAnimation = bullet2PhotoElement.animate([
        {
          transform: 'translate(-100%, -100%) rotate(-90deg) scale(1.07)'
        },
        {
          transform: 'translate(0, 0) rotate(-6deg) scale(1.07)'
        },
      ],
      {
        duration: 0.6*bulletAnimationPeriod,
        delay: 0.25*bulletAnimationPeriod,
        iterations: 1,
        fill: "both",
        easing: "ease-out"
      });
      this.bullet2PhotoAnimation.currentTime = currentTime;
      if(playState === 'running') {
        this.bullet2PhotoAnimation.play();
      }
      else {
        this.bullet2PhotoAnimation.pause();
      }

      currentTime = 0;
      playState = 'paused';

      if(this.bullet3PhotoContainerAnimation) {
        currentTime = this.bullet3PhotoContainerAnimation.currentTime;
        playState = this.bullet3PhotoContainerAnimation.playState;
      }
      this.bullet3PhotoContainerAnimation = bullet3PhotoContainerElement.animate([
        {
          opacity: '0',
          transform: 'scale(0.8)'
        },
        {
          opacity: '1',
          transform: 'scale(1.0)'
        },
      ],
      {
        duration: 0.25*bulletAnimationPeriod,
        delay: 0,
        iterations: 1,
        fill: "both",
        easing: "ease-out"
      });
      this.bullet3PhotoContainerAnimation.currentTime = currentTime;
      if(playState === 'running') {
        this.bullet3PhotoContainerAnimation.play();
      }
      else {
        this.bullet3PhotoContainerAnimation.pause();
      }

      currentTime = 0;
      playState = 'paused';

      if(this.bullet3PhotoAnimation) {
        currentTime = this.bullet3PhotoAnimation.currentTime;
        playState = this.bullet3PhotoAnimation.playState;
      }
      this.bullet3PhotoAnimation = bullet3PhotoElement.animate([
        {
          transform: 'translate(150%, -80%) rotate(90deg) scale(1.07)'
        },
        {
          transform: 'translate(0, 0) rotate(6deg) scale(1.07)'
        },
      ],
      {
        duration: 0.6*bulletAnimationPeriod,
        delay: 0.25*bulletAnimationPeriod,
        iterations: 1,
        fill: "both",
        easing: "ease-out"
      });
      this.bullet3PhotoAnimation.currentTime = currentTime;
      if(playState === 'running') {
        this.bullet3PhotoAnimation.play();
      }
      else {
        this.bullet3PhotoAnimation.pause();
      }
    }

    this.animation1.onfinish = () => {
      this.animation2.play();
    }
    this.animation2.onfinish = () => {
      this.animation3.play();

      this.bullet1IconAnimation.play();
      this.bullet1LineAnimation.play();
      this.bullet1TextAnimation.play();
      this.bullet1SubTextAnimation.play();
    }
    this.animation3.onfinish = () => {
      this.animation4.play();
    }
    this.animation4.onfinish = () => {
      this.animation5.play();

      this.bullet2IconAnimation.play();
      this.bullet2LineAnimation.play();
      this.bullet2TextAnimation.play();
      this.bullet2SubTextAnimation.play();
    }
    this.animation5.onfinish = () => {
      this.animation6.play();
    }
    this.animation6.onfinish = () => {
      this.animation7.play();

      this.bullet3IconAnimation.play();
      this.bullet3LineAnimation.play();
      this.bullet3TextAnimation.play();
      this.bullet3SubTextAnimation.play();
    }
    this.bullet3SubTextAnimation.onfinish = () => {
      if(this.state.screenWidth > 770) {
        this.bullet1PhotoContainerAnimation.play();
        this.bullet1PhotoAnimation.play();
      }
    }

    if(this.state.screenWidth > 770) {
      this.bullet1PhotoContainerAnimation.onfinish = () => {
        this.bullet2PhotoContainerAnimation.play();
        this.bullet2PhotoAnimation.play();
      }
      this.bullet2PhotoContainerAnimation.onfinish = () => {
        this.bullet3PhotoContainerAnimation.play();
        this.bullet3PhotoAnimation.play();
      }
    }

    this.setState({projectionAnimationSetUp: true});
  }

  async componentDidMount() {
    if (process.env.NODE_ENV === 'production') {
      window.gtag('config', 'UA-151418680-3', {'page_path': routes.MAIN_PATH});
    }

    this.setupAnimations();

    this.resizeListener = () => this.updateSize();
    window.addEventListener("resize", this.resizeListener);

    this.scrollListener = () => this.updateScroll();
    window.addEventListener("scroll", this.scrollListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
    window.removeEventListener("scroll", this.scrollListener);
  }

  updateSize() {
    this.setupAnimations();

    this.setState({
      screenWidth: window.innerWidth
    });
  }

  updateScroll() {
    if(!this.state.projectionAnimationStarted) {
      if(this.checkElementInViewport(this.projectionAnimation1.current)) {
        this.setState({
          projectionAnimationStarted: true
        });

        this.animation1.play();
      }
    }

    if(!this.state.fydLetterVisible) {
      if(this.checkElementInViewport(this.fLetterGroup.current, 0.6)) {
        this.setState({
          fydLetterVisible: true
        });

        this.fydBackgroundAnimation.play();
        this.fLetterGroupAnimation.play();
        this.fLettersAnimation.play();
        this.yLettersAnimation.play();
        this.dLetterGroupAnimation.play();
        this.dLettersAnimation.play();
      }
    }

    if(!this.state.callAnimationtriggered) {
      if(this.checkElementInViewport(this.callTextContainer.current, 0.6)) {
        this.setState({
          callAnimationtriggered: true
        });

        this.callTextContainerAnimation.play();
      }
    }
  }

  checkElementInViewport(element, triggerAreaPercentage=0.7) {
    const element1Rect = element.getBoundingClientRect();
    const elementVerticalCenter = 0.5*(element1Rect.top + element1Rect.bottom);

    const windowHeight = window.innerHeight;
    const triggerOffset = 0.5*(1 - triggerAreaPercentage)*windowHeight;

    const triggerReact = {
      top: triggerOffset,
      bottom: windowHeight - triggerOffset,
    };

    return elementVerticalCenter > triggerReact.top && elementVerticalCenter < triggerReact.bottom;
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    const contact = {...this.state.contact, [name]: value};

    this.setState({
      contact
    });
  }

  async sendDefaultContact() {
    if (process.env.NODE_ENV === 'production') {
      window.gtag('event', 'Send contact', {
        'event_category' : 'Form submit',
        'event_label' : 'Fale conosco'
      });
    }

    const contactHighlights = [];
    const contactMessages = [];

    if(this.state.contact.name.length <= 0) {
      contactHighlights.push('name');
      contactMessages.push('Nome não preencido');
    }

    if(this.state.contact.email.length <= 0) {
      contactHighlights.push('email');
      contactMessages.push('E-mail não preencido');
    }
    else if(this.state.contact.email.match(/.+@.+\..+/) === null) {
      contactHighlights.push('email');
      contactMessages.push('Formato de e-mail inválido');
    }

    if(this.state.contact.message.length <= 0) {
      contactHighlights.push('message');
      contactMessages.push('Mensagem não preenchida');
    }

    if(contactHighlights.length > 0) {
      this.setState({
        contactHighlights,
        showContactMessage: true,
        contactMessage: `${contactMessages.join('. ')}.`,
        contactMessageBackground: null,
        contactMessageColor: null
      });
    }
    else {
      this.setState({
        contactHighlights: [],
        sendingContact: true,
        contactMessage: "",
        showContactMessage: false
      });

      const data = {...this.state.contact};

      try {
        if(await postModel(routes.DEFAULT_CONTACT_POST_API, data)) {
          this.setState({
            sendingContact: false,
            showContactMessage: true,
            contactMessage: "Mensagem enviada com sucesso. Dentro de até 2 dias úteis nossa equipe entrará em contato com sua resposta.",
            contactMessageBackground: "#dbf3c1",
            contactMessageColor: "#456d19",
            contact: {
              name: "",
              email: "",
              message: ""
            }
          });

          if (process.env.NODE_ENV === 'production') {
            window.gtag('event', 'Contact requested', {
              'event_category' : 'Submit confirmation',
              'event_label' : 'Fale conosco'
            });
          }
        }
      }
      catch(errors) {
        let contactHighlights = [];

        // if(errors instanceof Array) {
        //   for(let error of errors) {
        //     switch (error.code) {
        //       case 102:
        //         for(let parameter of error.parameters) {
        //           switch (parameter.name) {
        //             case 'period':
        //               warningMessages.push('Período deve ser maior que 0');
        //               highlights.push('period');
        //
        //               break;
        //             default:
        //           }
        //         }
        //
        //         break;
        //       default:
        //     }
        //   }
        // }

        this.setState({
          contactHighlights,
          sendingContact: false,
          showContactMessage: true,
          contactMessage: DEFAULT_ERROR_MESSAGE,
          contactMessageBackground: null,
          contactMessageColor: null
        });

        return;
      }
    }
  }

  render() {
    return (
      <React.Fragment>

        <MainHeader
          location={this.props.location}
        />

        <main className="main-page">

          <section className="main-page__main-banner">

            <div className="main-page__main-banner__content">

              <div className="main-page__main-banner__content-wrapper">

                <h1 className="main-page__main-banner__header">

                  Conquiste o <span className="main-page__main-banner__header--highlighted">sonho</span> do negócio próprio

                </h1>

                <p className="main-page__main-banner__specifier">

                  Metodologia e tecnologia para impulsionar o sucesso de sua academia ou estúdio

                </p>

                <div className="main-page__main-banner__links-container">

                  <Link
                    className="main-page__main-banner__trial-link"
                    to={routes.MEETING_FORM_PATH}
                    onClick={() => {
                      if (process.env.NODE_ENV === 'production') {
                        window.gtag('event', 'Click', {
                          'event_category' : 'Main banner link',
                          'event_label' : 'Teste por 30 dias'
                        });
                      }
                    }}
                  >

                      Agende uma apresentação

                  </Link>

                </div>

              </div>

            </div>

            <div className="main-page__main-banner__images-container">

              <img
                className="main-page__main-banner__main-image"
                src={mainBannerLarge}
                srcSet={`${mainBannerLarge} 1700w,
                         ${mainBannerLarge2x} 3400w,
                         ${mainBannerBig} 1500w,
                         ${mainBannerBig2x} 3000w,
                         ${mainBannerMedium} 1300w,
                         ${mainBannerMedium2x} 2600w,
                         ${mainBannerSmall} 1080w,
                         ${mainBannerSmall2x} 2160w,
                         ${mainBannerVerySmall} 900w,
                         ${mainBannerVerySmall2x} 1800w,
                         ${mainBannerTiny} 700w,
                         ${mainBannerTiny2x} 1400w,
                         ${mainBannerVeryTiny} 470w,
                         ${mainBannerVeryTiny2x} 940w`}
                sizes="(max-width: 470px) 470px, (max-width: 700px) 700px, (max-width: 900px) 900px, (max-width: 1080px) 1080px, (max-width: 1300px) 1300px, (max-width: 1500px) 1500px, 1700px"
                alt=""
              />

            </div>

          </section>

          <section className="main-page__values-section" id="values-section">

            <div className="main-page__values-section__background-container">

              <img
                className="main-page__values-section__background-image"
                src={arrow}
                alt=""
              />

            </div>

            <div className="main-page__values-section__content">

              <h2 className="main-page__values-section__title">

                <span className="main-page__values-section__title--highlighted">Sucesso</span> projetado

              </h2>

              <hr className="main-page__values-section__hr"></hr>

              {this.state.screenWidth <= 770 &&
                <div className="main-page__values-section__image-carousel">

                  {this.state.screenWidth <= 470 ?
                    <React.Fragment>

                      <div className="main-page__values-section__image-carousel__sub-group">

                        <div className="main-page__values-section__photo-container--1">

                          <img
                            className="main-page__values-section__photo--1"
                            src={projectionPhoto1Large}
                            srcSet={`${projectionPhoto1Large} 300w,
                                     ${projectionPhoto1Large2x} 600w,
                                     ${projectionPhoto1Medium} 250w,
                                     ${projectionPhoto1Medium2x} 500w,
                                     ${projectionPhoto1Small} 180w,
                                     ${projectionPhoto1Small2x} 360w`}
                            sizes="(max-width: 550px) 180px, (max-width: 700px) 250px, 300px"
                            alt="Fachada de uma unidade FYD Club"
                          />

                        </div>

                        <div className="main-page__values-section__photo-container--2">

                          <img
                            className="main-page__values-section__photo--2"
                            src={projectionPhoto2Large}
                            srcSet={`${projectionPhoto2Large} 300w,
                                     ${projectionPhoto2Large2x} 600w,
                                     ${projectionPhoto2Medium} 250w,
                                     ${projectionPhoto2Medium2x} 500w,
                                     ${projectionPhoto2Small} 180w,
                                     ${projectionPhoto2Small2x} 360w`}
                            sizes="(max-width: 550px) 180px, (max-width: 700px) 250px, 300px"
                            alt="Caixa de salto com logotipo FYD"
                          />

                        </div>

                      </div>

                      <div className="main-page__values-section__photo-container--3">

                        <img
                          className="main-page__values-section__photo--3"
                          src={projectionPhoto3Large}
                          srcSet={`${projectionPhoto3Large} 180w,
                                   ${projectionPhoto3Large2x} 300w`}
                          sizes="180px"
                          alt="Entrada de uma unidade FYD"
                        />

                      </div>

                    </React.Fragment>:
                    <React.Fragment>

                      <div className="main-page__values-section__photo-container--1">

                        <img
                          className="main-page__values-section__photo--1"
                          src={projectionPhoto1Large}
                          srcSet={`${projectionPhoto1Large} 300w,
                                   ${projectionPhoto1Large2x} 600w,
                                   ${projectionPhoto1Medium} 250w,
                                   ${projectionPhoto1Medium2x} 500w,
                                   ${projectionPhoto1Small} 180w,
                                   ${projectionPhoto1Small2x} 360w`}
                          sizes="(max-width: 550px) 180px, (max-width: 700px) 250px, 300px"
                          alt="Fachada de uma unidade FYD Club"
                        />

                      </div>

                      <div className="main-page__values-section__photo-container--3">

                        <img
                          className="main-page__values-section__photo--3"
                          src={projectionPhoto3Large}
                          srcSet={`${projectionPhoto3Large} 180w,
                                   ${projectionPhoto3Large2x} 300w`}
                          sizes="180px"
                          alt="Entrada de uma unidade FYD"
                        />

                      </div>

                      <div className="main-page__values-section__photo-container--2">

                        <img
                          className="main-page__values-section__photo--2"
                          src={projectionPhoto2Large}
                          srcSet={`${projectionPhoto2Large} 300w,
                                   ${projectionPhoto2Large2x} 600w,
                                   ${projectionPhoto2Medium} 250w,
                                   ${projectionPhoto2Medium2x} 500w,
                                   ${projectionPhoto2Small} 180w,
                                   ${projectionPhoto2Small2x} 360w`}
                          sizes="(max-width: 550px) 180px, (max-width: 700px) 250px, 300px"
                          alt="Caixa de salto com logotipo FYD"
                        />

                      </div>

                    </React.Fragment>
                  }

                </div>
              }

              <div ref={this.projectionAnimation1Reference} className="main-page__values-section__topic--1">

                <div className="main-page__values-section__topic__background-detail-1--circle"></div>
                <div ref={this.projectionAnimation2CircleReference} className="main-page__values-section__topic__background-detail-1--circle-mask"></div>
                <div ref={this.projectionAnimation2} className="main-page__values-section__topic__background-detail-1--circle-spinner"></div>
                <div ref={this.projectionAnimation1} className="main-page__values-section__topic__background-detail-1--line"></div>

                {this.state.screenWidth > 770 &&
                  <div ref={this.projectioBullet1PhotoContainer} className="main-page__values-section__photo-container--1">

                    <img
                      className="main-page__values-section__photo--1"
                      src={projectionPhoto1Large}
                      srcSet={`${projectionPhoto1Large} 300w,
                               ${projectionPhoto1Large2x} 600w,
                               ${projectionPhoto1Medium} 250w,
                               ${projectionPhoto1Medium2x} 500w,
                               ${projectionPhoto1Small} 180w,
                               ${projectionPhoto1Small2x} 360w`}
                      sizes="(max-width: 550px) 180px, (max-width: 700px) 250px, 300px"
                      alt="Fachada de uma unidade FYD Club"
                      ref={this.projectioBullet1Photo}
                    />

                  </div>
                }

                <div ref={this.projectioBullet1Icon} className="main-page__values-section__topic__bullet">

                  <img
                    className="main-page__values-section__topic__bullet__image"
                    src={hexagon}
                    alt=""
                  />

                </div>

                <div className="main-page__values-section__topic__text-wrapper">

                  <p ref={this.projectioBullet1Text} className="main-page__values-section__topic__text">

                    Investimentos a partir de <span className="main-page__values-section__topic__text--highlighted">R$128.000,00</span>

                  </p>

                  <div ref={this.projectioBullet1Line} className="main-page__values-section__topic__underline"></div>

                  <p ref={this.projectioBullet1SubText} className="main-page__values-section__topic__sub-text">

                    para abrir o seu estúdio de treinamento funcional e pilates

                  </p>

                </div>

              </div>

              <div ref={this.projectionAnimation4Reference} className="main-page__values-section__topic--2">

                <div className="main-page__values-section__topic__background-detail-2--circle"></div>
                <div ref={this.projectionAnimation4CircleReference} className="main-page__values-section__topic__background-detail-2--circle-mask"></div>
                <div ref={this.projectionAnimation4} className="main-page__values-section__topic__background-detail-2--circle-spinner"></div>
                <div ref={this.projectionAnimation3} className="main-page__values-section__topic__background-detail-2--half-line"></div>

                {this.state.screenWidth > 770 &&
                  <div ref={this.projectioBullet2PhotoContainer} className="main-page__values-section__photo-container--2">

                    <img
                      className="main-page__values-section__photo--2"
                      src={projectionPhoto2Large}
                      srcSet={`${projectionPhoto2Large} 300w,
                               ${projectionPhoto2Large2x} 600w,
                               ${projectionPhoto2Medium} 250w,
                               ${projectionPhoto2Medium2x} 500w,
                               ${projectionPhoto2Small} 180w,
                               ${projectionPhoto2Small2x} 360w`}
                      sizes="(max-width: 550px) 180px, (max-width: 700px) 250px, 300px"
                      alt="Caixa de salto com logotipo FYD"
                      ref={this.projectioBullet2Photo}
                    />

                  </div>
                }

                <div className="main-page__values-section__topic__text-wrapper">

                  <p ref={this.projectioBullet2Text} className="main-page__values-section__topic__text">

                    Retorno do investimento em <span className="main-page__values-section__topic__text--highlighted">2,5 anos</span>

                  </p>

                  <div ref={this.projectioBullet2Line} className="main-page__values-section__topic__underline"></div>

                  <p ref={this.projectioBullet2SubText} className="main-page__values-section__topic__sub-text">

                    com o equilíbrio financeiro a partir do 8º mês

                  </p>

                </div>

                <div ref={this.projectioBullet2Icon} className="main-page__values-section__topic__bullet">

                  <img
                    className="main-page__values-section__topic__bullet__image"
                    src={hexagon}
                    alt=""
                  />

                </div>

              </div>

              <div ref={this.projectionAnimation6Reference} className="main-page__values-section__topic--3">

                <div className="main-page__values-section__topic__background-detail-3--circle"></div>
                <div ref={this.projectionAnimation6CircleReference} className="main-page__values-section__topic__background-detail-3--circle-mask"></div>
                <div ref={this.projectionAnimation6} className="main-page__values-section__topic__background-detail-3--circle-spinner"></div>
                <div ref={this.projectionAnimation5} className="main-page__values-section__topic__background-detail-3--half-line"></div>
                <div ref={this.projectionAnimation7} className="main-page__values-section__topic__background-detail-3--line"></div>

                {this.state.screenWidth > 770 &&
                  <div ref={this.projectioBullet3PhotoContainer} className="main-page__values-section__photo-container--3">

                    <img
                      className="main-page__values-section__photo--3"
                      src={projectionPhoto3Large}
                      srcSet={`${projectionPhoto3Large} 180w,
                               ${projectionPhoto3Large2x} 300w`}
                      sizes="180px"
                      alt="Entrada de uma unidade FYD"
                      ref={this.projectioBullet3Photo}
                    />

                  </div>
                }

                <div ref={this.projectioBullet3Icon} className="main-page__values-section__topic__bullet">

                  <img
                    className="main-page__values-section__topic__bullet__image"
                    src={hexagon}
                    alt=""
                  />

                </div>

                <div className="main-page__values-section__topic__text-wrapper">

                  <p ref={this.projectioBullet3Text} className="main-page__values-section__topic__text">

                    <span className="main-page__values-section__topic__text--highlighted">R$25.000,00</span> de faturamento mensal

                  </p>

                  <div ref={this.projectioBullet3Line} className="main-page__values-section__topic__underline"></div>

                  <p ref={this.projectioBullet3SubText} className="main-page__values-section__topic__sub-text">

                    com 140 alunos e lucro próximo de R$10.000,00

                  </p>

                </div>


              </div>

            </div>

          </section>

          <section className="main-page__highlight-section" id="highlight-section">

            <div className="main-page__highlight-section__content">

              <div className="main-page__highlight-section__content-wrapper">

                <div className="main-page__highlight-section__main-container">

                  <div className="main-page__highlight-section__text-container">

                    <h3 className="main-page__highlight-section__semi-title">

                      <div ref={this.fydBackground} className="main-page__highlight-section__semi-title__animated-background"></div>

                      <div ref={this.fLetterGroup} className="main-page__highlight-section__word-container">

                        <div className="main-page__highlight-section__semi-title--highlighted">F</div>

                        <div ref={this.fLetters} className="main-page__highlight-section__semi-title--animated">ollow</div>

                      </div>

                      <div ref={this.yLetterGroup} className="main-page__highlight-section__word-container">

                        <div className="main-page__highlight-section__semi-title--highlighted">Y</div>

                        <div ref={this.yLetters} className="main-page__highlight-section__semi-title--animated">our</div>

                      </div>

                      <div ref={this.dLetterGroup} className="main-page__highlight-section__word-container">

                        <div className="main-page__highlight-section__semi-title--highlighted">D</div>

                        <div ref={this.dLetters} className="main-page__highlight-section__semi-title--animated">reams</div>

                      </div>

                    </h3>

                    <p className="main-page__highlight-section__text--highlighted">

                      Mais do que um negócio promissor, queremos conscientizar as pessoas da
                      importância da atividade física para a saúde.
                    </p>

                    <p className="main-page__highlight-section__text">

                      Nosso modelo vai permitir que você ofereça o mesmo tipo de serviço acessível para
                      atletas profissionais a um custo igual ou inferior ao praticado pela maioria das
                      academias e estúdios.
                    </p>

                    <p className="main-page__highlight-section__text">

                      Confira {this.state.screenWidth <= 870 ? 'abaixo' : 'ao lado'} alguns dos pontos principais que nossa equipe vai te oferecer para que você alcance
                      o sucesso de seu negócio.
                    </p>

                  </div>

                  <ul className="main-page__highlight-section__feature-list">

                    <li className="main-page__highlight-section__feature--left">

                      <img
                        className="main-page__highlight-section__feature__icon"
                        src={featureOnlineForm}
                        alt=""
                      />

                      <div className="main-page__highlight-section__feature__wrapper">

                        <h3 className="main-page__highlight-section__feature__title">Treinamento de colaboradores</h3>

                        <p className="main-page__highlight-section__feature__description">

                          Além de treinar seus professores com as metodologias de treinamento de ponta, também passamos nosso know-how à sua equipe de gestão para que
                          ela possa administrar seu negócio com maestria.

                        </p>

                      </div>

                    </li>

                    {this.state.screenWidth <= 870 &&
                      <hr className="main-page__highlight-section__list-separator"/>
                    }

                    <li className="main-page__highlight-section__feature--right">

                      <img
                        className="main-page__highlight-section__feature__icon"
                        src={featureClassboard}
                        alt=""
                      />

                      <div className="main-page__highlight-section__feature__wrapper">

                        <h3 className="main-page__highlight-section__feature__title">Software integrado</h3>

                        <p className="main-page__highlight-section__feature__description">

                          Criamos uma plataforma que além de permitir que você gerencie seu negócio a partir de qualquer dispositivo com Intenet, ela também
                          oferece as ferramentas necessárias para que seus treinadores elevem seus serviços a outro patamar.

                        </p>

                      </div>

                    </li>

                    {this.state.screenWidth <= 870 &&
                      <hr className="main-page__highlight-section__list-separator"/>
                    }

                    <li className="main-page__highlight-section__feature--left">

                      <img
                        className="main-page__highlight-section__feature__icon"
                        src={featureFinancial}
                        alt=""
                      />

                      <div className="main-page__highlight-section__feature__wrapper">

                        <h3 className="main-page__highlight-section__feature__title">Crescimento com marketing</h3>

                        <p className="main-page__highlight-section__feature__description">

                          Nossa equipe vai assumir todo o planejamento de marketing nos meses iniciais de seu negócio exatamente para que você
                          alcance o equilíbrio financeiro o mais rápido possível.

                        </p>

                      </div>

                    </li>

                  </ul>

                </div>

              </div>

            </div>

            <div className="main-page__highlight-section__images-container">

              <div className="main-page__highlight-section__red-filter"></div>

              <img
                className="main-page__highlight-section__main-image"
                src={backgroundDetails}
                alt=""
              />

            </div>

          </section>

          <section className="main-page__call-to-action">

            <div className="main-page__call-to-action__content">

              <div className="main-page__call-to-action__content-wrapper">

                <h1 className="main-page__call-to-action__header">

                  <span className="main-page__call-to-action__header--highlighted">Agende uma reunião</span><br/> com nossos consultores

                </h1>

                <div ref={this.callTextContainer} className="main-page__call-to-action__text-wrapper">

                  <p ref={this.callText1} className="main-page__call-to-action__text">

                    Somos apaixonados pelo que fazemos e temos o prazer de lhe explicar
                    o motivo de acreditarmos tanto nesse negócio.

                  </p>

                  <p ref={this.callText2} className="main-page__call-to-action__text">

                    Estamos à sua disposição para agendarmos uma conversa sem compromisso para
                    que você entenda tudo que precise sobre esta oportunidade que estamos lhe oferecendo.

                  </p>

                </div>

                <div ref={this.callButton} className="main-page__call-to-action__links-container">

                  <Link
                    className="main-page__call-to-action__trial-link"
                    to={routes.MEETING_FORM_PATH}
                    onClick={() => {
                      if (process.env.NODE_ENV === 'production') {
                        window.gtag('event', 'Click', {
                          'event_category' : 'Main banner link',
                          'event_label' : 'Teste por 30 dias'
                        });
                      }
                    }}
                  >

                      Agende uma apresentação

                  </Link>

                </div>

              </div>

            </div>

            <div className="main-page__call-to-action__images-container">

              <img
                className="main-page__call-to-action__main-image"
                src={callToActionExtraLarge}
                srcSet={`${callToActionExtraLarge} 2000w,
                         ${callToActionExtraLarge2x} 4000w,
                         ${callToActionLarge} 1700w,
                         ${callToActionLarge2x} 3400w,
                         ${callToActionBig} 1500w,
                         ${callToActionBig2x} 3000w,
                         ${callToActionMedium} 1300w,
                         ${callToActionMedium2x} 2600w,
                         ${callToActionSmall} 1080w,
                         ${callToActionSmall2x} 2160w,
                         ${callToActionVerySmall} 900w,
                         ${callToActionVerySmall2x} 1800w,
                         ${callToActionTiny} 760w,
                         ${callToActionTiny2x} 1520w,
                         ${callToActionVeryTiny} 670w,
                         ${callToActionVeryTiny2x} 1340w`}
                sizes="(max-width: 470px) 670px, (max-width: 750px) 760px, (max-width: 900px) 900px, (max-width: 1080px) 1080px, (max-width: 1300px) 1300px, (max-width: 1500px) 1500px, (max-width: 1700px) 1700px, 2000px"
                alt=""
              />

            </div>

          </section>

          <section className="main-page__video-section" id="video-section">

            <div className="main-page__video-section__content">

              <div className="main-page__video-section__icon">

                <i className="fab fa-youtube"></i>

              </div>

              <h1 className="main-page__video-section__header">

                Trailer de apresentação

              </h1>

              <p className="main-page__video-section__specifier">

                Conquiste você também o sonho do negócio próprio

              </p>

              <a
                className="main-page__video-section__trial-link"
                href="https://youtu.be/vgIwKwF9hHA"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  if (process.env.NODE_ENV === 'production') {
                    window.gtag('event', 'Click', {
                      'event_category' : 'Video section link',
                      'event_label' : 'https://youtu.be/vgIwKwF9hHA'
                    });
                  }
                }}
              >
                Assistir o trailer
              </a>

            </div>

            <div className="main-page__video-section__images-container">

              <div className="main-page__video-section__red-filter"></div>

              {this.state.screenWidth <= 1550 ?
                <img
                  className="main-page__video-section__main-image"
                  src={panoramicLarge}
                  srcSet={`${panoramicLarge} 1620w,
                           ${panoramicLarge2x} 3240w,
                           ${panoramicMedium} 1418w,
                           ${panoramicMedium2x} 2835w,
                           ${panoramicSmall} 1337w,
                           ${panoramicSmall2x} 2673w,
                           ${panoramicVerySmall} 1188w,
                           ${panoramicVerySmall2x} 2376w,
                           ${panoramicTiny} 1040w,
                           ${panoramicTiny2x} 2079w`}
                  sizes="(max-width: 600px) 1040px, (max-width: 870px) 1188px, (max-width: 970px) 1337px, (max-width: 1200px) 1418px, 1620px"
                  alt="Banner mostrando o ambiente físico da FYD"
                />:
                <img
                  className="main-page__video-section__main-image"
                  src={panoramicExtraLarge}
                  srcSet={`${panoramicExtraLarge} 2931w,
                           ${panoramicExtraLarge2x} 5862w`}
                  sizes="2931px"
                  alt="Banner mostrando o ambiente físico da FYD"
                />
              }

            </div>

          </section>

          <section className="main-page__contact-section" id="contact-section">

            <div className="main-page__contact-section__content">

              <h2 className="main-page__contact-section__title">

                <span className="main-page__contact-section__title--highlighted">Fale</span> conosco

              </h2>

              <hr className="main-page__contact-section__hr"></hr>

              <p className="main-page__contact-section__semi-title">

                Informe os dados do formulário que entraremos em contato

              </p>

              <div className="main-page__contact-section__form-container">

                <div className="main-page__contact-section__warning-container">

                  <WarningMessage
                    message={this.state.contactMessage}
                    onClose={() => this.setState({contactHighlights: [], showContactMessage: false})}
                    visible={this.state.showContactMessage}
                    background={this.state.contactMessageBackground}
                    color={this.state.contactMessageColor}
                  />

                </div>

                <DefaultInput
                  className="main-page__contact-section__input"
                  name="name"
                  type="text"
                  isHighlighted={this.state.contactHighlights.includes('name')}
                  placeholder="Nome"
                  maxLength="128"
                  handleInputChange={(event) => this.handleInputChange(event)}
                  value={this.state.contact.name}
                  autoComplete="off"
                  disabled={this.state.sendingContact}
                />

                <DefaultInput
                  className="main-page__contact-section__input"
                  name="email"
                  type="email"
                  isHighlighted={this.state.contactHighlights.includes('email')}
                  placeholder="E-mail"
                  maxLength="128"
                  handleInputChange={(event) => this.handleInputChange(event)}
                  value={this.state.contact.email}
                  autoComplete="off"
                  disabled={this.state.sendingContact}
                />

                <DefaultInput
                  className="main-page__contact-section__input"
                  name="message"
                  type="textarea"
                  isHighlighted={this.state.contactHighlights.includes('message')}
                  placeholder="Mensagem"
                  rows="4"
                  handleInputChange={(event) => this.handleInputChange(event)}
                  value={this.state.contact.message || ''}
                  disabled={this.state.sendingContact}
                />

                <button
                  className={`main-page__contact-section__send-form-button${this.state.sendingContact ? '--active' : ''}`}
                  onClick={() => this.sendDefaultContact()}
                  disabled={this.state.sendingContact}
                >
                  {this.state.sendingContact ? 'Enviando...' : 'Enviar formulário'}
                </button>

              </div>

            </div>

          </section>

          <MainFooter />

        </main>

      </React.Fragment>
    );
  }
}

export default MainPage
