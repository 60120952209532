import {DEFAULT_ERROR_MESSAGE, STATE_API} from '../constants'

async function getStateToken() {
  try {
    let response = await fetch(STATE_API, {cache: 'no-store', credentials: 'same-origin'});

    if(!response.ok) {
      throw Error(DEFAULT_ERROR_MESSAGE);
    }

    let data = await response.json();

    return data.state;
  }
  catch(error) {
    console.log(error);
    return null;
  }
}

export {getStateToken};

function getAsLocalDatetime(isoString, appendUtcTimezone=true) {
  return new Date(isoString + (appendUtcTimezone ? 'Z' : ''));
}

function getAsLocalDate(isoDateString) {
  const date = new Date(
    parseInt(isoDateString.slice(0, 4)),
    parseInt(isoDateString.slice(5, 7)) - 1,
    isoDateString.slice(8, 10)
  );

  return date;
}

function getAsLocalDateString(isoDateString) {
  return getAsLocalDate(isoDateString).toLocaleDateString();
}

function getLocalDateIsoString(date) {
  return `${date.getFullYear().toLocaleString('pt-BR', {useGrouping: false, minimumIntegerDigits: 4})}-${(date.getMonth()+1).toLocaleString('pt-BR', {minimumIntegerDigits: 2})}-${date.getDate().toLocaleString('pt-BR', {minimumIntegerDigits: 2})}`
}

export {getAsLocalDatetime, getAsLocalDate, getAsLocalDateString, getLocalDateIsoString};

async function deleteModel(path) {
  let stateToken = await getStateToken();

  if(stateToken) {
    const headers = new Headers({
      'state': stateToken
    });

    try {
      let response = await fetch(path, {
        method: "DELETE",
        headers: headers,
        cache: 'no-store',
        credentials: 'same-origin'
      });

      if(!response.ok) {
        throw await response.json();
      }

      return true;
    }
    catch(error) {
      if(error.errors) {
        throw error.errors;
      }

      console.log(error);
    }
  }

  return null;
}

export {deleteModel};

async function postModel(path, data={}, getResponse = false) {
  let stateToken = await getStateToken();

  if(stateToken) {
    const headers = new Headers({
      'Content-Type': 'application/json',
      'state': stateToken
    });

    const body = JSON.stringify(data);

    try {
      let response = await fetch(path, {
        method: "POST",
        headers: headers,
        cache: 'no-store',
        body: body,
        credentials: 'same-origin'
      });

      if(!response.ok) {
        throw await response.json();
      }

      if(getResponse) {
        return await response.json();
      }

      return true;
    }
    catch(error) {
      if(error.errors) {
        throw error.errors;
      }

      if(error.code) {
        const errors = [error];
        throw errors;
      }

      console.log(error);
    }
  }

  window.alert(DEFAULT_ERROR_MESSAGE);
  throw Error(DEFAULT_ERROR_MESSAGE);
}

export {postModel};

async function patchModel(path, data, getResponse = false) {
  let stateToken = await getStateToken();

  if(stateToken) {
    const headers = new Headers({
      'Content-Type': 'application/json',
      'state': stateToken
    });

    const body = JSON.stringify(data);

    try {
      let response = await fetch(path, {
        method: "PATCH",
        headers: headers,
        cache: 'no-store',
        body: body,
        credentials: 'same-origin'
      });

      if(!response.ok) {
        throw await response.json();
      }

      if(getResponse) {
        return await response.json();
      }

      return true;
    }
    catch(error) {
      if(error.errors) {
        throw error.errors;
      }

      console.log(error);
    }
  }

  return null;
}

export {patchModel};

async function getModel(path) {
  let stateToken = await getStateToken();

  if(stateToken) {
    const headers = new Headers({
      'state': stateToken
    });

    try {
      let response = await fetch(path, {
        method: "GET",
        headers: headers,
        cache: 'no-store',
        credentials: 'same-origin'
      });

      if(!response.ok) {
        if(response.status === 401) {
          window.location.reload();
          return null;
        }

        throw await response.json();
      }

      return await response.json();
    }
    catch(error) {
      if(error.errors) {
        throw error.errors;
      }

      if(error.code) {
        const errors = [error];
        throw errors;
      }

      if(error instanceof TypeError) {
        return;
      }

      console.log(error);
    }
  }

  window.alert(DEFAULT_ERROR_MESSAGE);
  throw Error(DEFAULT_ERROR_MESSAGE);
}

export {getModel};

async function getModels(path) {
  let stateToken = await getStateToken();

  if(stateToken) {
    const headers = new Headers({
      'state': stateToken
    });

    try {
      let response = await fetch(path, {
        method: "GET",
        cache: 'no-store',
        credentials: 'same-origin',
        headers: headers,
      });

      if(!response.ok) {
        throw Error(DEFAULT_ERROR_MESSAGE);
      }

      return await response.json();
    }
    catch(error) {
      if(error instanceof TypeError) {
        return;
      }

      console.log(error);
    }
  }

  return null;
}

export {getModels};

function setInputCursor(target, position) {
  if (target.setSelectionRange) {
    target.setSelectionRange(position, position);
  }
  else if (target.createTextRange) {
    const range = target.createTextRange();
    range.collapse(true);
    range.moveEnd('character', position);
    range.moveStart('character', position);
    range.select();
  }
}

export {setInputCursor};

function getPhoneText(number) {
  if(!number) {
    return '';
  }

  let phone = number.replace(/\D/g,"");

  if(phone.length > 2) {
    return `(${phone.slice(0, 2)}) ${phone.slice(2)}`;
  }
  else if(phone.length > 0) {
    return '(' + phone;
  }

  return phone;
}

export {getPhoneText};

function getCurrencyText(value) {
  return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
}

export {getCurrencyText};

function getCpfText(number) {
  if(!number) {
    return '';
  }

  let cpf = number.replace(/\D/g,"");

  cpf = number.replace(/(\d{3})(\d)/,"$1.$2");
  cpf = cpf.replace(/(\d{3})(\d)/,"$1.$2");
  cpf = cpf.replace(/(\d{3})(\d{1,2})$/,"$1-$2");

  return cpf;
}

export {getCpfText};

function getCnpjText(number) {
  if(!number) {
    return '';
  }

  let cnpj = number.replace(/\D/g,"");

  cnpj = number.replace(/(\d{3})(\d)/,"$1.$2");
  cnpj = cnpj.replace(/(\d{3})(\d)/,"$1.$2");
  cnpj = cnpj.replace(/(\d{3})(\d{1,2})$/,"$1-$2");

  cnpj = number.replace(/(\d{2})(\d)/,"$1.$2");
  cnpj = cnpj.replace(/(\d{3})(\d)/,"$1.$2");
  cnpj = cnpj.replace(/(\d{3})(\d)/,"$1/$2");
  cnpj = cnpj.replace(/(\d{4})(\d+)$/,"$1-$2");

  return cnpj;
}

export {getCnpjText};

function getRgText(number) {
  if(!number) {
    return '';
  }

  let rg = number.replace(/\D/g,"");

  rg = number.replace(/(\d{2})(\d)/,"$1.$2");
  rg = rg.replace(/(\d{3})(\d)/,"$1.$2");
  rg = rg.replace(/(\d{3})(\d+)$/,"$1-$2");

  return rg;
}

export {getRgText};

function getCepText(number) {
  if(!number) {
    return '';
  }
  
  let cep = number.replace(/\D/g,"");

  cep = cep.replace(/(\d{5})(\d+)$/,"$1-$2");

  return cep;
}

export {getCepText};
