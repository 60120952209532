import React from 'react';
import { render } from 'react-snapshot';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import './init.scss';
import './general.scss';
import MainPage from './pages/main_page';
import MeetingFormPage from './pages/meeting_form_page';
import EditAccountPage from './pages/edit_account_page';
import * as serviceWorker from './serviceWorker';
import * as routes from './constants';

render((
  <BrowserRouter>

    <Switch>

      <Route
        path={routes.MEETING_FORM_PATH}
        component={MeetingFormPage}
      />

      <Route
        path={`${routes.EDIT_ACCOUNT_PATH}:authHash`}
        component={EditAccountPage}
      />

      <Route
        path={routes.MAIN_PATH}
        component={MainPage}
      />

      <Redirect from="/" to={routes.MAIN_PATH} />

    </Switch>

  </BrowserRouter>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
